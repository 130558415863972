import { Alert, LinearProgress, MenuItem, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router";
import { queryClient } from "../App";
import { enqueueSnackbar } from "notistack";
import {
  ZoneDeviated,
  getAZoneDeviated,
  getStopsWithoutDeviatedZone,
  getZoneDeviatedKml,
  updateAZoneDeviated,
} from "../api/zonesDeviated.api";
import { useZoneStore } from "../stores/zone.store.zus";
import BackButton from "./BackButton";
import StaticMapPreview from "./StaticMapPreview";
import StatusOptions from "./utility/StatusOptions";
import * as turf from "@turf/turf";
import { Polygon } from "geojson";
import {
  coordinatesToPolygonString,
  isValidPolygonString,
  kmlStringToGeoJson,
} from "../utility/geoUtils";

const inputBlock = {
  display: "block",
  // margin: "20px 0px",
};

export default function ZoneDeviatedSingle() {
  const { id } = useParams() as { id: string };
  const {
    data: stopsData,
    status: stopsStatus,
    isLoading: stopsIsLoading,
    isRefetching: stopsIsRefetching,
  } = useQuery("getStopsWithoutDeviatedZone", getStopsWithoutDeviatedZone, {
    refetchOnMount: true,
  });
  const {
    data: kmlExportData,
    isLoading: isKmlExportLoading,
    isRefetching: isKmlExportRefetching,
    refetch: refetchKmlExport,
  } = useQuery("getZoneKml", () => getZoneDeviatedKml(parseInt(id)), {
    enabled: false,
    onSuccess: (d) => {
      const url = window.URL.createObjectURL(d);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${zoneData?.zones[0].name}.kml`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      enqueueSnackbar("Zone KML exported succesfully.", {
        variant: "success",
      });
    },
    onError: (error: unknown) => {
      enqueueSnackbar(
        `There was a problem exporting this zone KML. Error: ${error}`,
        {
          variant: "error",
        }
      );
    },
  });
  const {
    data: zoneData,
    status,
    isLoading,
    isRefetching,
    refetch: refetchZone,
  } = useQuery("getAZoneDeviated", () => getAZoneDeviated(parseInt(id)), {
    refetchOnMount: true,
  });
  useEffect(() => {
    setEditEnabled(false);
    useZoneStore.getState().setPolygon(zoneData?.zones[0]?.polygon!);
  }, [zoneData]);
  const { mutateAsync: updateZoneMutation, isLoading: isZoneUpdating } =
    useMutation(updateAZoneDeviated, {
      onSuccess: () => {
        queryClient.invalidateQueries("getAZoneDeviated");
        queryClient.invalidateQueries("getZonesDeviated");
        queryClient.invalidateQueries("getStopsWithoutDeviatedZone");

        enqueueSnackbar("Zone was succesfully edited.", {
          variant: "success",
        });
      },
      onError: (error: unknown) => {
        enqueueSnackbar(
          `There was a problem editing this zone. Error: ${
            (error as any).response.data.error
          }`,
          {
            variant: "error",
          }
        );
      },
    });
  const [editEnabled, setEditEnabled] = useState(false);
  const [updatedZone, setUpdatedZone] = useState<ZoneDeviated>({
    id: parseInt(id),
  });
  const [attemptedUpload, setAttemptedUpload] = useState(false);

  async function updateZone(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (useZoneStore.getState().polygon) {
      updatedZone.polygon = useZoneStore.getState().polygon;
    }
    await updateZoneMutation(updatedZone);
    setEditEnabled(false);
    useZoneStore.getState().setEditMode(false);
    queryClient.invalidateQueries("getAZoneDeviated");
    queryClient.invalidateQueries("getZonesDeviated");
  }

  const kmlInput = useRef<HTMLInputElement>();
  const [kmlUpdateError, setKmlUpdateError] = useState<boolean>(true);
  const [isPolyStringValid, setIsPolyStringValid] = useState<boolean>(false);

  async function kmlInputHandler() {
    try {
      //Check File Exists
      setAttemptedUpload(true);
      setKmlUpdateError(false);
      setIsPolyStringValid(false);
      if (!kmlInput.current?.files) {
        throw `No File attached.`;
      }
      //Check File name contains .kml
      let fileName = kmlInput.current?.files![0];
      if (fileName.name.match(/.kml/) == null) {
        setKmlUpdateError(true);
        throw `Incorrect file type.`;
      }
      let file = kmlInput.current?.files![0] as Blob;

      if (file) {
        const reader = new FileReader();
        reader.addEventListener(
          "load",
          async () => {
            try {
              if (reader.result) {
                const kmlGeoJson = kmlStringToGeoJson(reader.result as string);

                let firstPolygon: Polygon | null = null;
                turf.flattenEach(kmlGeoJson, (feature) => {
                  if (
                    feature.geometry.type === "Polygon" &&
                    feature.geometry.coordinates.length === 1 // Make sure it is a simple polygon (no holes)
                  ) {
                    firstPolygon = feature.geometry;
                    return false; // break out of flattenEach
                  }
                });

                if (!firstPolygon) {
                  throw new Error("Object must contain a Simple Polygon");
                }

                let coordinateString = coordinatesToPolygonString(
                  (firstPolygon as Polygon).coordinates[0]
                );

                if (!isValidPolygonString(coordinateString)) {
                  throw new Error("Invalid Polygon String.");
                }

                //BIND POLYGON TO ZUS STATE
                useZoneStore.getState().setPolygon(coordinateString);
                setIsPolyStringValid(true);
              }
            } catch (error) {
              console.log(error);
              setKmlUpdateError(true);
            }
          },
          false
        );
        reader.readAsText(file);
      }
    } catch (error) {
      setKmlUpdateError(true);
    }
  }
  // useEffect(() => {
  //   let stop = stopsData?.stops.filter((stop) => {
  //     return stop.id == updatedZone.stop_id;
  //   })[0].name;
  //   setUpdatedZone({
  //     ...updatedZone,
  //     name: stop,
  //   });
  // }, [updatedZone.stop_id]);
  if (isLoading || isRefetching || isZoneUpdating) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }
  return (
    <div>
      <BackButton></BackButton>
      <h2>
        Deviated Zone Information{" "}
        <span style={{ display: "flex", alignItems: "center" }}>
          <button
            onClick={(e) => {
              useZoneStore.getState().setEditMode(!editEnabled);
              setEditEnabled(!editEnabled);
            }}
            className="button-primary-sm"
          >
            Edit
          </button>
          <button
            style={{ marginLeft: "10px" }}
            onClick={(e) => {
              refetchKmlExport();
            }}
            className="button-primary-sm"
          >
            Export KML
          </button>
        </span>
      </h2>
      <div
        className={editEnabled ? "asset-container editmode" : "asset-container"}
      >
        {zoneData?.zones[0] && (
          <form onSubmit={updateZone}>
            <div className="field-block">
              <span className="label">Zone Name</span>
              {editEnabled ? (
                <TextField
                  required
                  inputProps={{ required: true }}
                  size="small"
                  style={{ width: "300px" }}
                  label="Name"
                  defaultValue={zoneData.zones[0].name}
                  onChange={(e) => {
                    setUpdatedZone({ ...updatedZone, name: e.target.value });
                  }}
                ></TextField>
              ) : (
                zoneData.zones[0].name
              )}
            </div>
            <div className="field-block">
              <span className="label">Stop</span>
              {editEnabled ? (
                <TextField
                  required
                  select
                  inputProps={{ min: 1 }}
                  style={{ width: "300px" }}
                  size="small"
                  placeholder="Select a stop"
                  label="Stop"
                  defaultValue={zoneData.zones[0].stop_id}
                  onChange={(e) => {
                    setUpdatedZone({
                      ...updatedZone,
                      stop_id: parseInt(e.target.value),
                    });
                  }}
                >
                  {/* stopsData only has unused stops, so add the current stop manually and select it */}
                  <MenuItem
                    key={zoneData.zones[0].stop_id}
                    value={zoneData.zones[0].stop_id}
                    selected={true}
                  >
                    {zoneData.zones[0].stop_name}
                  </MenuItem>
                  {stopsData?.stops.map((stop) => {
                    return (
                      <MenuItem key={stop.id} value={stop.id}>
                        {stop.name}
                      </MenuItem>
                    );
                  })}
                </TextField>
              ) : (
                zoneData.zones[0].stop_name
              )}
            </div>
            <div className="field-block">
              <span className="label">Status</span>
              {editEnabled ? (
                <TextField
                  inputProps={{ required: true }}
                  size="small"
                  style={{ width: "300px" }}
                  label="Status"
                  select
                  defaultValue={zoneData.zones[0].status}
                  onChange={(e) => {
                    setUpdatedZone({
                      ...updatedZone,
                      status: parseInt(e.target.value),
                    });
                  }}
                >
                  <MenuItem value={0}>Enabled</MenuItem>
                  <MenuItem value={1}>Disabled</MenuItem>
                  {/* <MenuItem value={2}>Hidden</MenuItem> */}
                </TextField>
              ) : (
                <StatusOptions
                  status={zoneData?.zones[0].status!}
                ></StatusOptions>
              )}
            </div>
            <div className="field-block">
              <span className="label">Priority</span>
              {editEnabled ? (
                <TextField
                  required
                  size="small"
                  inputProps={{ min: 0, max: 255 }}
                  style={{ width: "300px" }}
                  label="Priority"
                  type="number"
                  defaultValue={zoneData.zones[0].priority}
                  onChange={(e) => {
                    setUpdatedZone({
                      ...updatedZone,
                      priority: parseInt(e.target.value),
                    });
                  }}
                  onWheel={(e) =>
                    e.target instanceof HTMLElement && e.target.blur()
                  }
                ></TextField>
              ) : (
                zoneData?.zones[0].priority!
              )}
            </div>

            {editEnabled && (
              <div className="field-block">
                <span className="label">KML Upload</span>
                <TextField
                  type="file"
                  sx={inputBlock}
                  error={!isPolyStringValid && attemptedUpload}
                  onChange={(e) => {
                    kmlInputHandler();
                  }}
                  inputRef={kmlInput}
                ></TextField>
                {isPolyStringValid && attemptedUpload && (
                  <StaticMapPreview
                    polygonString={useZoneStore.getState().polygon!}
                  ></StaticMapPreview>
                )}
                {!isPolyStringValid && attemptedUpload && (
                  <Alert severity="error">
                    Invalid Polygon string formatting.
                  </Alert>
                )}
                {useZoneStore.getState().polygon &&
                  !kmlUpdateError &&
                  attemptedUpload && (
                    <Alert severity="success">Succesfully uploaded file.</Alert>
                  )}
              </div>
            )}

            {editEnabled && (
              <div className="button-container">
                <button
                  className="button-outline btn"
                  onClick={(e) => {
                    setEditEnabled(false);
                    useZoneStore.getState().setEditMode(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  disabled={isZoneUpdating}
                  className="button-primary btn"
                >
                  Save
                </button>
              </div>
            )}
          </form>
        )}
      </div>
    </div>
  );
}
