import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  LinearProgress,
  MenuItem,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router";
import { queryClient } from "../App";
import { getVehicleTypes } from "../api/vehicle_types.api";
import { createAVehicle } from "../api/vehicles.api";
import BackButton from "./BackButton";
const inputBlock = {
  display: "block",
  margin: "20px 0px",
};

interface CreateVehiclePayload {
  vehicle_type_id?: number;
  rider_display_name?: string | null;
  internal_number?: number;
  default_job_id?: number | null;
  status?: number;
  vin_number?: string;
  tag_number?: string;
  nickname?: string;
  sort_priority?: number;
}

export default function VehicleCreate() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [showAdvancedCreate, setShowAdvancedCreate] = useState(false);
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [createVehiclePayload, setCreateVehiclePayload] =
    useState<CreateVehiclePayload>({
      internal_number: 0,
      sort_priority: 0,
      status: 0,
      vin_number: "00000000000000000",
      tag_number: "000000",
      rider_display_name: "",
      nickname: "",
    });

  const {
    data: vehicleTypes,
    isLoading,
    isRefetching: vehicleTypesIsRefetching,
  } = useQuery("getVehicleTypes", getVehicleTypes, {
    refetchOnMount: "always",
  });
  useEffect(() => {
    setCreateVehiclePayload({
      ...createVehiclePayload,
      vehicle_type_id:
        vehicleTypes?.vehicle_types.length == 1
          ? vehicleTypes.vehicle_types[0].id
          : 0,
    });
  }, [vehicleTypes]);
  const { mutateAsync: saveNewVehicle, isLoading: isMutationLoading } =
    useMutation(createAVehicle, {
      onSuccess: () => {
        queryClient.invalidateQueries("getVehicles");
        queryClient.invalidateQueries("getAVehicles");
        queryClient.invalidateQueries("getVehicleTypes");
        enqueueSnackbar("Vehicle was succesfully created.", {
          variant: "success",
        });
        navigate("/dashboard/vehicles");
      },
      onError: (error: unknown) => {
        enqueueSnackbar(
          `There was a problem creating this vehicle. Error: ${error}`,
          {
            variant: "error",
          }
        );
      },
    });

  async function submitHandler(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    let payload = createVehiclePayload;
    if (payload.rider_display_name == "") {
      payload.rider_display_name = null;
    }
    await saveNewVehicle(payload);
  }
  if (isLoading || vehicleTypesIsRefetching) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }

  return (
    <div className="create-asset-container">
      <BackButton></BackButton>
      <h2>Create a vehicle</h2>
      {vehicleTypes && (
        <div
          className="create-asset-form"
          style={{ marginTop: "20px", maxWidth: "250px" }}
        >
          <form onSubmit={submitHandler}>
            <TextField
              required={!showAdvancedCreate}
              inputProps={{ maxLength: 255 }}
              size="small"
              type="number"
              fullWidth
              sx={inputBlock}
              label="Vehicle Number"
              onChange={(e) => {
                setVehicleNumber(e.target.value);
                if (e.target.value == undefined || e.target.value == "") {
                  setCreateVehiclePayload({
                    ...createVehiclePayload,
                    nickname: ``,
                    rider_display_name: ``,
                    internal_number: 0,
                  });
                  return;
                }
                setCreateVehiclePayload({
                  ...createVehiclePayload,
                  nickname: `#${e.target.value}`,
                  rider_display_name: `#${e.target.value}`,
                  internal_number: parseInt(e.target.value),
                });
              }}
              onWheel={(e) =>
                e.target instanceof HTMLElement && e.target.blur()
              }
            ></TextField>

            <TextField
              size="small"
              fullWidth
              required
              error={
                createVehiclePayload.vehicle_type_id == 0 ||
                createVehiclePayload.vehicle_type_id == undefined ||
                createVehiclePayload.vehicle_type_id == null
              }
              onChange={(e) => {
                setCreateVehiclePayload({
                  ...createVehiclePayload,
                  vehicle_type_id: parseInt(e.target.value),
                });
              }}
              defaultValue={
                createVehiclePayload.vehicle_type_id ||
                vehicleTypes.vehicle_types.length == 1
                  ? vehicleTypes.vehicle_types[0].id
                  : 0
              }
              label="Vehicle Type"
              select
            >
              {vehicleTypes.vehicle_types.length > 1 && (
                <MenuItem disabled value={0}>
                  Select a Vehicle Type
                </MenuItem>
              )}
              {vehicleTypes?.vehicle_types.map((v) => {
                return (
                  <MenuItem key={v.id} value={v.id}>
                    {v.name} - {v.max_passengers}/{v.max_passengers_hops}
                  </MenuItem>
                );
              })}
            </TextField>
            <FormGroup>
              <FormControlLabel
                style={{ margin: "20px 0px 20px 0px" }}
                control={
                  <Checkbox
                    checked={showAdvancedCreate}
                    onChange={(e) => {
                      setShowAdvancedCreate(e.target.checked);
                    }}
                  />
                }
                label="Show Advanced Options"
              />
            </FormGroup>
            {showAdvancedCreate && (
              <>
                <div>
                  <TextField
                    required
                    inputProps={{ maxLength: 255 }}
                    size="small"
                    sx={inputBlock}
                    label="Nickname"
                    fullWidth
                    value={createVehiclePayload.nickname}
                    onChange={(e) => {
                      setCreateVehiclePayload({
                        ...createVehiclePayload,
                        nickname: e.target.value,
                      });
                    }}
                  ></TextField>
                </div>
                <TextField
                  inputProps={{ maxLength: 255 }}
                  size="small"
                  fullWidth
                  sx={inputBlock}
                  label="Rider Display Name"
                  value={createVehiclePayload.rider_display_name}
                  onChange={(e) => {
                    setCreateVehiclePayload({
                      ...createVehiclePayload,
                      rider_display_name: e.target.value,
                    });
                  }}
                ></TextField>
                <TextField
                  required
                  inputProps={{ min: 0, max: 4294967295 }}
                  size="small"
                  type="number"
                  fullWidth
                  sx={inputBlock}
                  label="Internal Number"
                  value={createVehiclePayload.internal_number}
                  onChange={(e) => {
                    setCreateVehiclePayload({
                      ...createVehiclePayload,
                      internal_number: parseInt(e.target.value),
                    });
                  }}
                  onWheel={(e) =>
                    e.target instanceof HTMLElement && e.target.blur()
                  }
                ></TextField>
                <TextField
                  required
                  inputProps={{ maxength: 17 }}
                  size="small"
                  fullWidth
                  sx={inputBlock}
                  label="VIN"
                  value={createVehiclePayload.vin_number}
                  onChange={(e) => {
                    setCreateVehiclePayload({
                      ...createVehiclePayload,
                      vin_number: e.target.value,
                    });
                  }}
                ></TextField>
                <TextField
                  required
                  inputProps={{ maxLength: 16 }}
                  size="small"
                  fullWidth
                  sx={inputBlock}
                  label="Tag Number"
                  value={createVehiclePayload.tag_number}
                  onChange={(e) => {
                    setCreateVehiclePayload({
                      ...createVehiclePayload,
                      tag_number: e.target.value,
                    });
                  }}
                ></TextField>
                <TextField
                  size="small"
                  fullWidth
                  label="Status"
                  select
                  value={createVehiclePayload.status}
                  onChange={(e) => {
                    setCreateVehiclePayload({
                      ...createVehiclePayload,
                      status: parseInt(e.target.value),
                    });
                  }}
                >
                  <MenuItem value={0}>Enabled</MenuItem>
                  <MenuItem value={1}>Disabled</MenuItem>
                </TextField>
                <TextField
                  size="small"
                  type="number"
                  fullWidth
                  sx={inputBlock}
                  label="Sort Priority"
                  value={createVehiclePayload.sort_priority}
                  onChange={(e) => {
                    if (Number.isNaN(e.target.value)) {
                      setCreateVehiclePayload({
                        ...createVehiclePayload,
                        sort_priority: 0,
                      });
                    }
                    setCreateVehiclePayload({
                      ...createVehiclePayload,
                      sort_priority: parseInt(e.target.value),
                    });
                  }}
                  onWheel={(e) =>
                    e.target instanceof HTMLElement && e.target.blur()
                  }
                ></TextField>
              </>
            )}
            <button
              className="button-primary"
              style={{ margin: "20px 0px" }}
              type="submit"
              disabled={createVehiclePayload.vehicle_type_id == 0}
            >
              Create Vehicle
            </button>
          </form>
        </div>
      )}
    </div>
  );
}
