import { LinearProgress, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router";
import { queryClient } from "../App";
import { Address, getAnAddress, updateAnAddress } from "../api/address.api";
import BackButton from "./BackButton";
import PlaceholderCharacter from "./utility/PlaceholderCharacter";
import StaticMapPreviewPoint from "./StaticMapPreviewPoint";

export default function AddressSingle() {
  const { id } = useParams() as { id: string };

  const { data, status, isLoading, isRefetching } = useQuery(
    "getAnAddress",
    () => getAnAddress(parseInt(id)),
    { refetchOnMount: true }
  );
  const { mutateAsync, isLoading: isMutationLoading } = useMutation(
    updateAnAddress,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getAddresses");
        queryClient.invalidateQueries("getAnAddress");
      },
    }
  );
  const [editEnabled, setEditEnabled] = useState(false);
  const [updatedAddress, setUpdatedAddress] = useState<Address>({
    id: parseInt(id),
  });

  async function updateAddress() {
    await mutateAsync(updatedAddress);
    setEditEnabled(false);
  }
  useEffect(() => {
    setUpdatedAddress({ id: parseInt(id) });
  }, [editEnabled]);

  if (isLoading || isRefetching || isMutationLoading) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }
  return (
    <div>
      <BackButton></BackButton>
      <h2>
        Address Information{" "}
        <button
          onClick={(e) => {
            setEditEnabled(!editEnabled);
          }}
          className="button-primary-sm"
        >
          Edit
        </button>
      </h2>
      <div
        className={editEnabled ? "asset-container editmode" : "asset-container"}
      >
        <div className="field-block">
          <span className="label">Name</span>
          {editEnabled ? (
            <TextField
              disabled
              size="small"
              inputProps={{ maxLength: 255 }}
              defaultValue={data?.addresses[0].name}
              placeholder={data?.addresses[0].name}
              onChange={(e) =>
                setUpdatedAddress({
                  ...updatedAddress,
                  name: e.target.value,
                })
              }
            ></TextField>
          ) : (
            <>
              {data?.addresses[0].name}
              {data?.addresses[0].latitude && data?.addresses[0].longitude && (
                <StaticMapPreviewPoint
                  coordinate={{
                    lat: data?.addresses[0].latitude,
                    lng: data?.addresses[0].longitude,
                  }}
                ></StaticMapPreviewPoint>
              )}
            </>
          )}
        </div>
        <div className="field-block">
          <span className="label">Latitude</span>
          {editEnabled ? (
            <TextField
              type="number"
              size="small"
              inputProps={{ maxLength: 255 }}
              defaultValue={data?.addresses[0].latitude}
              onWheel={(e) =>
                e.target instanceof HTMLElement && e.target.blur()
              }
              onChange={(e) =>
                setUpdatedAddress({
                  ...updatedAddress,
                  latitude: parseFloat(e.target.value),
                })
              }
            ></TextField>
          ) : (
            data?.addresses[0].latitude
          )}
        </div>
        <div className="field-block">
          <span className="label">Longitude</span>
          {editEnabled ? (
            <TextField
              type="number"
              size="small"
              inputProps={{ maxLength: 255 }}
              defaultValue={data?.addresses[0].longitude}
              onWheel={(e) =>
                e.target instanceof HTMLElement && e.target.blur()
              }
              onChange={(e) =>
                setUpdatedAddress({
                  ...updatedAddress,
                  longitude: parseFloat(e.target.value),
                })
              }
            ></TextField>
          ) : (
            data?.addresses[0].longitude
          )}
        </div>

        <div className="field-block">
          <span className="label">Driver Notes</span>
          {editEnabled ? (
            <TextField
              type="text"
              size="small"
              inputProps={{ maxLength: 255 }}
              defaultValue={data?.addresses[0].additional_info}
              onChange={(e) =>
                setUpdatedAddress({
                  ...updatedAddress,
                  additional_info: e.target.value,
                })
              }
            ></TextField>
          ) : (
            <>
              {data?.addresses[0].additional_info ? (
                data?.addresses[0].additional_info
              ) : (
                <PlaceholderCharacter></PlaceholderCharacter>
              )}
            </>
          )}
        </div>
        {editEnabled && (
          <div className="button-container">
            <button
              className="button-outline btn"
              onClick={(e) => {
                setEditEnabled(false);
              }}
            >
              Cancel
            </button>
            <button className="button-primary btn" onClick={updateAddress}>
              Save
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
