import {
  Autocomplete,
  LinearProgress,
  MenuItem,
  TextField,
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router";
import { queryClient } from "../App";
import { getMerchants } from "../api/merchants.api";
import {
  Promotion,
  getAPromotion,
  updateAPromotion,
} from "../api/promotions.api";
import { useSysParamsStore } from "../stores/sysParams.store.zus";
import { convertDateToTimeSinceEpoc } from "../utility/convertDateToTimeSinceEpoc";
import BackButton from "./BackButton";
import ImagePreview from "./ImagePreview";
import FileUploader from "./utility/FileUploader";
import StatusOptions from "./utility/StatusOptions";

export default function PromotionsSingle() {
  const { id } = useParams() as { id: string };

  const { data, status, isLoading, isRefetching } = useQuery(
    "getAPromotion",
    () => getAPromotion(parseInt(id)),
    { refetchOnMount: true }
  );
  const { mutateAsync: updatePromotion, isLoading: isMutationLoading } =
    useMutation(updateAPromotion, {
      onSuccess: () => {
        queryClient.invalidateQueries("getPromotions");
        queryClient.invalidateQueries("getAPromotion");
        enqueueSnackbar("Promotion was succesfully edited.", {
          variant: "success",
        });
      },
      onError: (error: unknown) => {
        enqueueSnackbar(
          `There was a problem editing this promotion. Error: ${error}`,
          {
            variant: "error",
          }
        );
      },
    });
  const [editEnabled, setEditEnabled] = useState(false);
  const [updatedPromotion, setUpdatedPromotion] = useState<Promotion>({
    id: parseInt(id),
  });
  function iconUploadHandler(url: string) {
    setUpdatedPromotion({
      ...updatedPromotion,
      thumb: url,
    });
  }
  async function updatePromotionHandler() {
    await updatePromotion(updatedPromotion);
    setEditEnabled(false);
  }
  useEffect(() => {
    setUpdatedPromotion({ id: parseInt(id) });
  }, [editEnabled]);

  const {
    data: merchantsData,
    isLoading: merchantsIsLoading,
    isRefetching: merchantsIsRefetching,
  } = useQuery("getMerchants", getMerchants, { refetchOnMount: true });

  if (
    isLoading ||
    isRefetching ||
    isMutationLoading ||
    merchantsIsLoading ||
    merchantsIsRefetching
  ) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }
  return (
    <div>
      <BackButton></BackButton>
      <h2>
        Promotion Information{" "}
        <button
          onClick={(e) => {
            setEditEnabled(!editEnabled);
          }}
          className="button-primary-sm"
        >
          Edit
        </button>
      </h2>
      <div
        className={editEnabled ? "asset-container editmode" : "asset-container"}
      >
        <form onSubmit={updatePromotionHandler}>
          <div className="field-block">
            <span className="label">Merchant</span>
            {editEnabled && merchantsData ? (
              <Autocomplete
                disablePortal
                disableClearable={true}
                id="merchant-select"
                value={
                  merchantsData.merchants.find(
                    (merchant) => merchant.id === updatedPromotion.merchant_id
                  ) ||
                  merchantsData.merchants.find(
                    (merchant) =>
                      merchant.id === data?.promotions[0].merchant_id
                  )
                }
                onChange={(val, newVal) =>
                  setUpdatedPromotion({
                    ...updatedPromotion,
                    merchant_id: newVal?.id!,
                  })
                }
                options={merchantsData.merchants}
                getOptionLabel={(merchant) => merchant.name || ""}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Merchant Select" required />
                )}
              ></Autocomplete>
            ) : (
              data?.promotions[0].merchant_name
            )}
          </div>
          <div className="field-block">
            <span className="label">Title</span>
            {editEnabled ? (
              <TextField
                required
                inputProps={{ autoComplete: "off" }}
                size="small"
                label="Title"
                defaultValue={data?.promotions[0].title}
                onChange={(e) => {
                  setUpdatedPromotion({
                    ...updatedPromotion,
                    title: e.target.value,
                  });
                }}
              ></TextField>
            ) : (
              data?.promotions[0].title
            )}
          </div>
          <div className="field-block">
            <span className="label">Details</span>
            {editEnabled ? (
              <TextField
                required
                inputProps={{ autoComplete: "off" }}
                size="small"
                label="Details"
                defaultValue={data?.promotions[0].details}
                onChange={(e) => {
                  setUpdatedPromotion({
                    ...updatedPromotion,
                    details: e.target.value,
                  });
                }}
              ></TextField>
            ) : (
              data?.promotions[0].details
            )}
          </div>
          <div className="field-block">
            <span className="label">Begin Date</span>
            {editEnabled ? (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["TimePicker"]}>
                  <DateTimePicker
                    label="Begin Date"
                    defaultValue={
                      dayjs(
                        new Date(data?.promotions[0].begin_date! * 1000)
                      ).tz(
                        useSysParamsStore
                          .getState()
                          .getParamByName("global_timezone")?.value
                      ) as any
                    }
                    onChange={(e: Date | null) => {
                      if (e) {
                        let d = new Date(e);
                        setUpdatedPromotion({
                          ...updatedPromotion,
                          begin_date: convertDateToTimeSinceEpoc(d),
                        });
                      }
                    }}
                  ></DateTimePicker>
                </DemoContainer>
              </LocalizationProvider>
            ) : (
              <>
                {dayjs(data?.promotions[0].begin_date! * 1000)
                  .tz(
                    useSysParamsStore
                      .getState()
                      .getParamByName("global_timezone")?.value
                  )
                  .format("MM/DD/YYYY hh:mm a")}
              </>
            )}
          </div>
          <div className="field-block">
            <span className="label">End Date</span>
            {editEnabled ? (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["TimePicker"]}>
                  <DateTimePicker
                    label="End Date"
                    defaultValue={
                      dayjs(new Date(data?.promotions[0].end_date! * 1000)).tz(
                        useSysParamsStore
                          .getState()
                          .getParamByName("global_timezone")?.value
                      ) as any
                    }
                    onChange={(e: Date | null) => {
                      if (e) {
                        let d = new Date(e);
                        setUpdatedPromotion({
                          ...updatedPromotion,
                          end_date: convertDateToTimeSinceEpoc(d),
                        });
                      }
                    }}
                  ></DateTimePicker>
                </DemoContainer>
              </LocalizationProvider>
            ) : (
              <>
                {" "}
                {dayjs(data?.promotions[0].end_date! * 1000)
                  .tz(
                    useSysParamsStore
                      .getState()
                      .getParamByName("global_timezone")?.value
                  )
                  .format("MM/DD/YYYY hh:mm a")}
              </>
            )}
          </div>
          <div className="field-block">
            <span className="label">Priority</span>
            {editEnabled ? (
              <TextField
                required
                inputProps={{ autoComplete: "off" }}
                size="small"
                label="Priority"
                type="number"
                defaultValue={data?.promotions[0].priority}
                onChange={(e) => {
                  setUpdatedPromotion({
                    ...updatedPromotion,
                    priority: parseInt(e.target.value),
                  });
                }}
              ></TextField>
            ) : (
              data?.promotions[0].priority
            )}
          </div>
          <div className="field-block">
            <span className="label">Status</span>
            {editEnabled ? (
              <TextField
                inputProps={{ min: 0, max: 1, required: true }}
                size="small"
                type="number"
                label="Status"
                select
                defaultValue={data?.promotions[0].status}
                onChange={(e) => {
                  setUpdatedPromotion({
                    ...updatedPromotion,
                    status: parseInt(e.target.value),
                  });
                }}
              >
                <MenuItem value={0}>Enabled</MenuItem>
                <MenuItem value={1}>Disabled</MenuItem>
              </TextField>
            ) : (
              <StatusOptions
                status={data?.promotions[0].status!}
              ></StatusOptions>
            )}
          </div>
          <div className="field-block">
            <span className="label">Thumbnail</span>
            {editEnabled ? (
              <FileUploader
                queryKey={"promotionEditThumbnailUrl"}
                success={iconUploadHandler}
                labelText="Upload a thumbnail"
              ></FileUploader>
            ) : (
              <ImagePreview
                imageUrl={data?.promotions[0].thumb!}
                showPlaceHolderDashes
              ></ImagePreview>
            )}
          </div>

          {editEnabled && (
            <div className="button-container">
              <button
                className="button-outline btn"
                onClick={(e) => {
                  setEditEnabled(false);
                }}
              >
                Cancel
              </button>
              <button className="button-primary btn">Save</button>
            </div>
          )}
        </form>
      </div>
    </div>
  );
}
