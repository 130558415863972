import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  LinearProgress,
  MenuItem,
  Switch,
  TextField,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router";
import { queryClient } from "../App";
import BackButton from "./BackButton";

import {
  DatePicker,
  DateValidationError,
  LocalizationProvider,
  TimePicker,
  TimeValidationError,
} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {
  ShiftScheduled,
  createAShift,
  getShiftAllowableJobs,
  getShiftAllowableVehicles,
} from "../api/shiftsScheduled.api";
import { useShiftScheduledCreateStore } from "../stores/shiftsCreate.store.zus";
import {
  getDayjsFromSecondsSinceMidnight,
  getSecondsFromMidnight,
} from "../utility/shiftsScheduledTimeHelper";
const inputBlock = {
  display: "block",
  margin: "20px 0px",
};

export interface ShiftScheduledCreate extends ShiftScheduled {
  amount?: number;
}

export default function ShiftsScheduledCreate() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { mutateAsync: saveNewShift, isLoading: isSaveNewShiftLoading } =
    useMutation(createAShift, {
      onSuccess: () => {
        queryClient.invalidateQueries("getShifts");
        enqueueSnackbar("Shift was succesfully created.", {
          variant: "success",
        });
        navigate("/dashboard/shifts-scheduled");
      },
      onError: (error: unknown) => {
        enqueueSnackbar(
          `There was a problem creating this shift. Error: ${error}`,
          {
            variant: "error",
          }
        );
      },
    });
  const {
    data: jobData,
    isLoading: jobDataIsLoading,
    isRefetching: jobDataIsRefetching,
  } = useQuery("getJobs", getShiftAllowableJobs, { refetchOnMount: true });
  const {
    data: vehicleData,
    isLoading: vehicleDataIsLoading,
    isRefetching: vehicleDataIsRefetching,
  } = useQuery("getAllowedVehicleData", getShiftAllowableVehicles, {
    refetchOnMount: true,
  });
  const {
    job_name,
    job_id,
    vehicle_id,
    days,
    start_date,
    end_date,
    start_time,
    end_time,
    start_lead_time,
    status,
    startLeadTimeEnabled,
    selectedDays,
    amount,
    setDays,
    setEndDate,
    setEndTime,
    setJobId,
    setStartDate,
    setStartTime,
    setVehicleId,
    setStatus,
    setAmount,
    setStartLeadTime,
    setSelectedDays,
    getFullShiftCreated,
    setStartLeadTimeEnabled,
  } = useShiftScheduledCreateStore();

  useEffect(() => {
    if (days) {
      if (days == "1,2,3,4,5,6,7") {
        setSelectedDays([0]);
      } else {
        const d = days.split(",").map((day) => parseInt(day, 10));
        setSelectedDays(d);
      }
    } else {
      setSelectedDays([0]);
    }
  }, [days]);
  function isLoadingOrRefetching() {
    if (jobDataIsLoading || jobDataIsRefetching) {
      return true;
    }
    if (vehicleDataIsLoading || vehicleDataIsRefetching) {
      return true;
    }
    return false;
  }
  function formComplete() {
    if (
      end_date &&
      start_date &&
      start_time !== undefined &&
      end_time !== undefined &&
      job_id &&
      vehicle_id &&
      !isEndDateError() &&
      endTimeError == null &&
      endDateError == null
    ) {
      return true;
    }
    return false;
  }
  function checkboxHandler(value: number) {
    let array = selectedDays;
    if (value == 0) {
      array = [0];
    }
    if (value != 0) {
      if (array.includes(value)) {
        let s = array.filter((num) => num != value);
        array = s;
      } else {
        array.push(value);
        let s = array.filter((num) => num > 0);
        array = s;
      }
    }
    setSelectedDays(array);
  }
  const [endDateError, setEndDateError] = useState<DateValidationError | null>(
    null
  );
  function isEndDateError() {
    if (
      start_date &&
      end_date &&
      dayjs(start_date).toDate() > dayjs(end_date).toDate()
    ) {
      return true;
    }
    return false;
  }
  const [endTimeError, setEndTimeError] = useState<TimeValidationError | null>(
    null
  );

  useEffect(() => {
    if (start_date == end_date) {
      setSelectedDays([0]);
    }
  }, [start_date, end_date]);
  function showDayOfTheWeek() {
    if (
      start_date &&
      end_date &&
      start_date !== end_date &&
      !isEndDateError()
    ) {
      return true;
    }
    return false;
  }
  async function submitHandler(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    let payload = { ...getFullShiftCreated() };
    if (!startLeadTimeEnabled) {
      payload.start_lead_time = null;
    }
    if (payload.start_lead_time != null) {
      payload.start_lead_time *= 60;
    }
    if (payload.end_time == 0) {
      payload.end_time = 86400;
    }

    if (selectedDays[0] == 0) {
      payload.days = "1,2,3,4,5,6,7";
    } else {
      payload.days = selectedDays.sort((a, b) => a - b).join(",");
    }
    await saveNewShift(payload);
  }
  if (isLoadingOrRefetching()) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }

  return (
    <div className="create-asset-container">
      <BackButton></BackButton>
      <h2>Create a Shift Block</h2>
      {jobData && vehicleData && (
        <div
          className="create-asset-form"
          style={{ marginTop: "20px", maxWidth: "250px" }}
        >
          <form onSubmit={submitHandler}>
            <TextField
              inputProps={{ required: true, min: 0 }}
              required
              style={{ width: "300px", margin: "20px 0px 20px 0px" }}
              label="Job"
              select
              value={job_id}
              error={jobData?.jobs.length == 0}
              helperText={
                jobData?.jobs.length == 0
                  ? "Please create an enabled job with shift mode scheduled."
                  : ""
              }
              onChange={(e) => {
                setJobId(parseInt(e.target.value));
              }}
            >
              {jobData?.jobs.map((j) => {
                return (
                  <MenuItem key={j.id} value={j.id}>
                    {j.name}
                  </MenuItem>
                );
              })}
            </TextField>
            <TextField
              inputProps={{ required: true, min: 0 }}
              required
              style={{ width: "300px" }}
              label="Vehicle Type"
              select
              value={vehicle_id}
              onChange={(e) => {
                setVehicleId(parseInt(e.target.value));
              }}
            >
              {vehicleData?.vehicles.map((v) => {
                return (
                  <MenuItem key={v.vehicle_id} value={v.vehicle_id}>
                    {v.vehicle_type_name}
                  </MenuItem>
                );
              })}
            </TextField>
            <div style={{ margin: "20px 0px" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  sx={{ minWidth: 300 }}
                  label="Start Time"
                  value={
                    start_time
                      ? getDayjsFromSecondsSinceMidnight(start_time)
                      : (null as any)
                  }
                  onChange={(e: Date | null) => {
                    setStartTime(undefined);
                    if (dayjs(e).isValid()) {
                      setStartTime(getSecondsFromMidnight(dayjs(e).toDate()));
                    }
                  }}
                ></TimePicker>
              </LocalizationProvider>
            </div>
            <div style={{ margin: "20px 0px" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  sx={{ minWidth: 300 }}
                  label="End Time"
                  value={
                    end_time
                      ? getDayjsFromSecondsSinceMidnight(end_time)
                      : (null as any)
                  }
                  onError={(error) => {
                    setEndTimeError(error);
                  }}
                  onChange={(e: Date | null) => {
                    setEndTime(undefined);
                    if (dayjs(e).isValid()) {
                      setEndTime(getSecondsFromMidnight(dayjs(e).toDate()));
                    }
                  }}
                ></TimePicker>
              </LocalizationProvider>
            </div>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={startLeadTimeEnabled}
                    onChange={(e) => {
                      setStartLeadTimeEnabled(!startLeadTimeEnabled);
                    }}
                  />
                }
                label="Enable Start Lead Time"
              />
            </FormGroup>
            {startLeadTimeEnabled && (
              <TextField
                required
                fullWidth
                type="number"
                inputProps={{ required: true }}
                sx={inputBlock}
                label="Start Lead Time"
                helperText={
                  "Number of minutes before shift starts in which shift can start receiving rides"
                }
                value={start_lead_time}
                onChange={(e) => {
                  setStartLeadTime(parseInt(e.target.value));
                }}
                onWheel={(e) =>
                  e.target instanceof HTMLElement && e.target.blur()
                }
              ></TextField>
            )}
            <div style={{ margin: "20px 0px" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  sx={{ minWidth: 300 }}
                  label="Start Date"
                  value={
                    start_date
                      ? dayjs(start_date) // Ensure this is a dayjs object
                      : (null as any)
                  }
                  onChange={(e: Date | null) => {
                    if (e) {
                      let d = dayjs(e);
                      if (d.isValid()) {
                        setStartDate(d.format("YYYY-MM-DD"));
                      }
                    }
                  }}
                ></DatePicker>
              </LocalizationProvider>
            </div>
            <div style={{ margin: "20px 0px" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  sx={{ minWidth: 300 }}
                  label="End Date"
                  value={end_date ? dayjs(end_date) : (null as any)}
                  onError={(error) => {
                    setEndDateError(error);
                  }}
                  slotProps={{
                    textField: {
                      variant: "outlined",
                      error: endDateError == "invalidDate" || isEndDateError(),
                      helperText: isEndDateError()
                        ? "End date must be after the start date."
                        : "",
                    },
                  }}
                  onChange={(e: Date | null) => {
                    if (e) {
                      let d = dayjs(e);
                      if (d.isValid()) {
                        setEndDate(d.format("YYYY-MM-DD"));
                      }
                    }
                  }}
                ></DatePicker>
              </LocalizationProvider>
            </div>
            {showDayOfTheWeek() && (
              <>
                <hr />
                <h2 style={{ margin: "20px 0px" }}>
                  Which days of the week does this scheduled apply to?
                </h2>
                <div>
                  <Box sx={{ display: "flex" }}>
                    <FormGroup style={{ marginRight: "60px" }}>
                      <FormControlLabel
                        value={0}
                        control={
                          <Checkbox
                            checked={selectedDays.includes(0)}
                            onChange={(e) => {
                              checkboxHandler(parseInt(e.target.value));
                            }}
                          />
                        }
                        label="All Days"
                      />
                      <FormControlLabel
                        value={1}
                        control={
                          <Checkbox
                            checked={selectedDays.includes(1)}
                            onChange={(e) => {
                              checkboxHandler(parseInt(e.target.value));
                            }}
                          />
                        }
                        label="Monday"
                      />
                      <FormControlLabel
                        value={2}
                        control={
                          <Checkbox
                            checked={selectedDays.includes(2)}
                            onChange={(e) => {
                              checkboxHandler(parseInt(e.target.value));
                            }}
                          />
                        }
                        label="Tuesday"
                      />
                      <FormControlLabel
                        value={3}
                        control={
                          <Checkbox
                            checked={selectedDays.includes(3)}
                            onChange={(e) => {
                              checkboxHandler(parseInt(e.target.value));
                            }}
                          />
                        }
                        label="Wednesday"
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormControlLabel
                        value={4}
                        control={
                          <Checkbox
                            checked={selectedDays.includes(4)}
                            onChange={(e) => {
                              checkboxHandler(parseInt(e.target.value));
                            }}
                          />
                        }
                        label="Thursday"
                      />
                      <FormControlLabel
                        value={5}
                        control={
                          <Checkbox
                            checked={selectedDays.includes(5)}
                            onChange={(e) => {
                              checkboxHandler(parseInt(e.target.value));
                            }}
                          />
                        }
                        label="Friday"
                      />
                      <FormControlLabel
                        value={6}
                        control={
                          <Checkbox
                            checked={selectedDays.includes(6)}
                            onChange={(e) => {
                              checkboxHandler(parseInt(e.target.value));
                            }}
                          />
                        }
                        label="Saturday"
                      />
                      <FormControlLabel
                        value={7}
                        control={
                          <Checkbox
                            checked={selectedDays.includes(7)}
                            onChange={(e) => {
                              checkboxHandler(parseInt(e.target.value));
                            }}
                          />
                        }
                        label="Sunday"
                      />
                    </FormGroup>
                  </Box>
                </div>
              </>
            )}
            {showDayOfTheWeek() && <hr />}
            <div>
              <TextField
                style={{ width: "300px", margin: "15px 0px" }}
                label="How many per day?"
                select
                value={amount}
                onChange={(e) => {
                  setAmount(parseInt(e.target.value));
                }}
              >
                {Array.from({ length: 20 }, (_, i) => (
                  <MenuItem key={i} value={i + 1}>
                    {i + 1} {i == 0 ? "(Default)" : ""}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <button
              className="button-primary"
              style={{ margin: "20px 0px" }}
              type="submit"
              disabled={!formComplete() || isSaveNewShiftLoading}
            >
              {isSaveNewShiftLoading ? (
                <CircularProgress
                  size="15px"
                  style={{ color: "white" }}
                ></CircularProgress>
              ) : (
                <span className="button-container">Create Shift Block</span>
              )}
            </button>
          </form>
        </div>
      )}
    </div>
  );
}
