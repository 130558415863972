import { useZoneStore } from "../stores/zone.store.zus";
import EditableMap from "./EditableMap";
import ViewMap from "./ViewMap";
import ZoneSingle from "./ZoneSingle";

export default function ZoneSingleWrapper() {
  const zone = useZoneStore();
  return (
    <>
      <ZoneSingle></ZoneSingle>
      {!zone.editMode && <ViewMap></ViewMap>}
      {zone.editMode && <EditableMap></EditableMap>}
    </>
  );
}
