import React, { useState } from "react";
import { GoogleMap, PolygonF } from "@react-google-maps/api";
import { polygonStringToGoogleMapCoordinates } from "../utility/polygonStringToGoogleMapsCoordinates";
import { polygonStringToGoogleMapsCoordinateGetFirstCoordinate } from "../utility/polygonStringToGoogleMapsCoordinateGetFirstCoordinate";
import { useZoneStore } from "../stores/zone.store.zus";
const containerStyle = {
  width: "600px",
  height: "400px",
};
const inputBlock = {
  display: "block",
  margin: "20px 0px",
};
const options = {
  fillColor: "lightblue",
  fillOpacity: 0,
  strokeColor: "red",
  strokeOpacity: 1,
  strokeWeight: 2,
  clickable: true,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1,
};
export default function ViewMap() {
  const zoneStore = useZoneStore();
  const [activePolygon, setActivePolygon] = useState<google.maps.Polygon>();

  return (
    <>
      <GoogleMap
        mapContainerStyle={containerStyle}
        options={{
          streetViewControl: false,
        }}
        onLoad={(map) => {
          const bounds = new window.google.maps.LatLngBounds();
          // bounds.extend(new window.google.maps.LatLngBounds(lat, lng));
          map.fitBounds(bounds);
        }}
        center={
          zoneStore.polygon
            ? polygonStringToGoogleMapsCoordinateGetFirstCoordinate(
                zoneStore.polygon
              )
            : undefined
        }
        // zoom={16}
        id="drawing-manager-example"
      >
        <PolygonF
          onLoad={(polygon: google.maps.Polygon) => setActivePolygon(polygon)}
          options={options}
          paths={
            zoneStore.polygon
              ? polygonStringToGoogleMapCoordinates(zoneStore.polygon)
              : undefined
          }
        ></PolygonF>
      </GoogleMap>
    </>
  );
}
