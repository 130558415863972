import { useZoneStore } from "../stores/zone.store.zus";
import EditableMap from "./EditableMap";
import ViewMap from "./ViewMap";
import ZoneDeviatedSingle from "./ZoneDeviatedSingle";

export default function ZoneDeviatedSingleWrapper() {
  const zone = useZoneStore();
  return (
    <>
      <ZoneDeviatedSingle></ZoneDeviatedSingle>
      {!zone.editMode && <ViewMap></ViewMap>}
      {zone.editMode && <EditableMap></EditableMap>}
    </>
  );
}
