import LinearProgress from "@mui/material/LinearProgress";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router";
import { getAnAccessAccount } from "../api/accessAccounts.api";
import {
  getAnAccessAccountsAccessCodesByCreationDate,
  getAnAccessAccountsCodeHistory,
} from "../api/accessCodes.api";
import { useSysParamsStore } from "../stores/sysParams.store.zus";

export default function AccessAccountSingleCodeHistory() {
  const navigate = useNavigate();
  const { id } = useParams() as { id: string };

  const columns: GridColDef[] = [
    {
      field: "creation_date",
      headerName: "Creation Date",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {dayjs(params.row.creation_date * 1000)
              .tz(
                useSysParamsStore.getState().getParamByName("global_timezone")
                  ?.value
              )
              .format("MM/DD/YYYY hh:mm a")}
          </>
        );
      },
    },
    {
      field: "number_of_codes",
      headerName: "Number of Codes",
      flex: 1,
    },
    {
      field: "options",
      headerName: "Options",
      sortable: false,
      flex: 0.5,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <>
            <button
              onClick={(e) => {
                setSelectedCreationDate(params.row.creation_date);
              }}
              className="button-primary-sm"
            >
              Download CSV
            </button>
          </>
        );
      },
    },
  ];
  const {
    data: accessAccountData,
    status,
    isLoading,
    isRefetching,
  } = useQuery(
    "getAccessAccountsCodeHistory",
    () => getAnAccessAccountsCodeHistory(parseInt(id)),
    {
      refetchOnMount: true,
    }
  );
  const {
    data: getAccessAccount,
    status: getAccessAccountStatus,
    isLoading: getAccessAccountIsLoading,
    isRefetching: getAccessAccountIsRefetching,
  } = useQuery([], () => getAnAccessAccount(parseInt(id)), {
    refetchOnMount: true,
  });
  const [selectedCreationDate, setSelectedCreationDate] = useState<number>();

  const {
    data: reportData,
    status: reportStatus,
    isLoading: reportIsLoading,
    isRefetching: reportIsRefetching,
    refetch,
  } = useQuery(
    ["getAccessAccountCodeHistoryExport", selectedCreationDate],
    () =>
      getAnAccessAccountsAccessCodesByCreationDate(
        parseInt(id),
        selectedCreationDate
      ),
    {
      enabled: false,
      onSuccess: (d) => {
        const url = window.URL.createObjectURL(d);
        const a = document.createElement("a");
        a.href = url;
        //access account name added to file download
        a.download = `${getAccessAccount?.access_accounts[0].name}-${selectedCreationDate}-access_codes.csv`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        enqueueSnackbar("Successfully generated access codes report.", {
          variant: "success",
        });
      },
      onError: (error: unknown) => {
        enqueueSnackbar(
          `There was a problem creating this report. Error: ${error}`,
          {
            variant: "error",
          }
        );
      },
    }
  );
  useEffect(() => {
    if (selectedCreationDate) {
      refetch();
    }
  }, [selectedCreationDate]);
  if (isLoading || isRefetching || getAccessAccountIsLoading) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }
  return (
    <div>
      <div className="header-container">
        <h2>Created Access Code History</h2>
        {/* <button
          className="button-primary"
          onClick={() => {
            navigate("/dashboard/access-accounts/create");
          }}
        >
          Create New Access Account
        </button> */}
      </div>
      <div style={{ height: 700, width: "100%", margin: "20px 0px" }}>
        <DataGrid
          rowHeight={40}
          headerHeight={45}
          rows={accessAccountData?.code_history ?? []}
          getRowId={(row) => row.creation_date}
          columns={columns}
          pageSize={15}
          rowsPerPageOptions={[15]}
        />
      </div>
    </div>
  );
}
