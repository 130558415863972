import axios, { AxiosResponse } from "axios";
import { useAuthStore } from "../stores/auth.store.zus";
import { formatDateToSimpleString } from "../utility/formatDateToSimpleString";

export interface GenericReportResponse {
  success: boolean;
  response: string;
}

export async function getMerchantsExport(): Promise<Blob> {
  const token = useAuthStore.getState().token;
  const res = await axios.get<GenericReportResponse, AxiosResponse<Blob>>(
    `${process.env.REACT_APP_API_BASE_URL}/reporting/merchants-export`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
      responseType: "blob",
    }
  );
  return res.data;
}
export interface OriginDestinationReportInput {
  startDate: Date | null;
  endDate: Date | null;
  jobId: number | null;
  precision: number;
  showVehicleInformation: boolean;
  showComboId: boolean;
  showRating: boolean;
  showWaitTime: boolean;
  showRideId: boolean;
}
export async function getOriginDestinationReport(
  originDestinationReportInput: OriginDestinationReportInput
): Promise<Blob> {
  const token = useAuthStore.getState().token;
  let payload: any = { ...originDestinationReportInput };
  if (payload.endDate) {
    payload.endDate = formatDateToSimpleString(payload.endDate);
  }
  if (payload.startDate) {
    payload.startDate = formatDateToSimpleString(payload.startDate);
  }
  const res = await axios.post<GenericReportResponse, AxiosResponse<Blob>>(
    `${process.env.REACT_APP_API_BASE_URL}/reporting/origin-destination`,
    payload,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
      responseType: "blob",
    }
  );
  return res.data;
}

export interface EtaEarlyLateReportInput {
  startDate: Date | null;
  endDate: Date | null;
  jobId: number | null;
  rideMode: number | null;
}
export async function getEtaEarlyLateReport(
  etaEarlyLateReportInput: EtaEarlyLateReportInput
): Promise<Blob> {
  const token = useAuthStore.getState().token;
  let payload: any = { ...etaEarlyLateReportInput };
  if (payload.endDate) {
    payload.endDate = formatDateToSimpleString(payload.endDate);
  }
  if (payload.startDate) {
    payload.startDate = formatDateToSimpleString(payload.startDate);
  }
  const res = await axios.post<GenericReportResponse, AxiosResponse<Blob>>(
    `${process.env.REACT_APP_API_BASE_URL}/reporting/eta-early-late`,
    payload,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
      responseType: "blob",
    }
  );
  return res.data;
}

export interface RiderFeedbackReportInput {
  startDate: Date | null;
  endDate: Date | null;
  jobId: number | null;
  showRideId: boolean;
  showDriverName: boolean;
  showRiderName: boolean;
}
export async function getRiderFeedbackReport(
  riderFeedbackReportInput: RiderFeedbackReportInput
): Promise<Blob> {
  const token = useAuthStore.getState().token;
  let payload: any = { ...riderFeedbackReportInput };
  if (payload.endDate) {
    payload.endDate = formatDateToSimpleString(payload.endDate);
  }
  if (payload.startDate) {
    payload.startDate = formatDateToSimpleString(payload.startDate);
  }
  const res = await axios.post<GenericReportResponse, AxiosResponse<Blob>>(
    `${process.env.REACT_APP_API_BASE_URL}/reporting/rider-feedback`,
    payload,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
      responseType: "blob",
    }
  );
  return res.data;
}

export interface RideDistributionReportInput {
  startDate: Date | null;
  endDate: Date | null;
  jobId: number | null;
  range: number | null;
}
export async function getRideDistributionReport(
  rideDistributionReportInput: RideDistributionReportInput
): Promise<Blob> {
  const token = useAuthStore.getState().token;
  let payload: any = { ...rideDistributionReportInput };
  if (payload.endDate) {
    payload.endDate = formatDateToSimpleString(payload.endDate);
  }
  if (payload.startDate) {
    payload.startDate = formatDateToSimpleString(payload.startDate);
  }
  const res = await axios.post<GenericReportResponse, AxiosResponse<Blob>>(
    `${process.env.REACT_APP_API_BASE_URL}/reporting/ride-distribution`,
    payload,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
      responseType: "blob",
    }
  );
  return res.data;
}

export interface RiderNotFoundReportInput {
  startDate: Date | null;
  endDate: Date | null;
  jobId: number | null;
}

export async function getRiderNotFoundReport(
  RiderNotFoundReportInput: RiderNotFoundReportInput
): Promise<Blob> {
  const token = useAuthStore.getState().token;
  let payload: any = { ...RiderNotFoundReportInput };
  if (payload.endDate) {
    payload.endDate = formatDateToSimpleString(payload.endDate);
  }
  if (payload.startDate) {
    payload.startDate = formatDateToSimpleString(payload.startDate);
  }
  const res = await axios.post<GenericReportResponse, AxiosResponse<Blob>>(
    `${process.env.REACT_APP_API_BASE_URL}/reporting/rider-not-found`,
    payload,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
      responseType: "blob",
    }
  );
  return res.data;
}
export interface KioskReportInput {
  startDate: Date | null;
  endDate: Date | null;
  jobId: number | null;
}

export async function getKioskReport(
  KioskReportInput: KioskReportInput
): Promise<Blob> {
  const token = useAuthStore.getState().token;
  let payload: any = { ...KioskReportInput };
  if (payload.endDate) {
    payload.endDate = formatDateToSimpleString(payload.endDate);
  }
  if (payload.startDate) {
    payload.startDate = formatDateToSimpleString(payload.startDate);
  }
  const res = await axios.post<GenericReportResponse, AxiosResponse<Blob>>(
    `${process.env.REACT_APP_API_BASE_URL}/reporting/kiosk`,
    payload,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
      responseType: "blob",
    }
  );
  return res.data;
}

export interface FareReportInput {
  startDate: Date | null;
  endDate: Date | null;
  jobId: number | null;
}
export async function getFareReport(
  fareReportInput: FareReportInput
): Promise<Blob> {
  const token = useAuthStore.getState().token;
  let payload: any = { ...fareReportInput };
  if (payload.endDate) {
    payload.endDate = formatDateToSimpleString(payload.endDate);
  }
  if (payload.startDate) {
    payload.startDate = formatDateToSimpleString(payload.startDate);
  }
  const res = await axios.post<GenericReportResponse, AxiosResponse<Blob>>(
    `${process.env.REACT_APP_API_BASE_URL}/reporting/fare`,
    payload,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
      responseType: "blob",
    }
  );
  return res.data;
}
type FormParamTypes =
  | "date_selector"
  | "text_input"
  | "number_input"
  | "job_selector"
  | "date_range_selector";

interface FormParam {
  variable: string;
  form_label: string;
  type: FormParamTypes;
  default_value?: any;
}

export interface CustomReport {
  id: number;
  name: string;
  description: string;
  form_params: FormParam[] | null;
  filename: string | null;
  enabled: boolean;
}

export interface GetCustomReports {
  reports: CustomReport[];
}

export async function getCustomReportsList(): Promise<GetCustomReports> {
  const token = useAuthStore.getState().token;
  const res = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/reporting/custom-reports`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
}

export async function getCustomReport(customReportInput: any): Promise<Blob> {
  const token = useAuthStore.getState().token;
  let payload: any = { ...customReportInput };
  const res = await axios.post<GenericReportResponse, AxiosResponse<Blob>>(
    `${process.env.REACT_APP_API_BASE_URL}/reporting/custom-reports`,
    payload,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
      responseType: "blob",
    }
  );
  return res.data;
}
