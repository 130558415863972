import { ThemeProvider, createTheme } from "@mui/material/styles";
import { LoadScript } from "@react-google-maps/api";
import { SnackbarProvider } from "notistack";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import AccessAccountCreate from "./components/AccessAccountCreate";
import AccessAccountLanding from "./components/AccessAccountLanding";
import AccessAccountSingle from "./components/AccessAccountSingle";
import AddressesEditMultiple from "./components/AddressesEditMultiple";
import AddressesLanding from "./components/AddressesLanding";
import AddressSingle from "./components/AddressesSingle";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import DashboardHome from "./components/DashboardHome";
import DriverRecapOverview from "./components/DriverRecapOverview";
import DriverCreate from "./components/DriversCreate";
import DriversOverview from "./components/DriversOverview";
import DriversSingle from "./components/DriversSingle";
import FareCreate from "./components/FaresCreate";
import FaresOverview from "./components/FaresOverview";
import FaresSingle from "./components/FaresSingle";
import HubRecapOverview from "./components/HubRecapOverview";
import JobCategories from "./components/JobCategories";
import JobCategoryCreate from "./components/JobCategoryCreate";
import JobCategorySingle from "./components/JobCategorySingle";
import JobCreate from "./components/JobCreate";
import JobSingle from "./components/JobSingle";
import JobsAccessAccountsRulesCreate from "./components/JobsAccessAccountsRulesCreate";
import JobsAccessAccountsRulesOverview from "./components/JobsAccessAccountsRulesOverview";
import JobsAccessAccountsRulesSingle from "./components/JobsAccessAccountsRulesSingle";
import JobsOverview from "./components/JobsOverview";
import MerchantCategories from "./components/MerchantCategories";
import MerchantCategoryCreate from "./components/MerchantCategoryCreate";
import MerchantCategorySingle from "./components/MerchantCategorySingle";
import MerchantCreate from "./components/MerchantCreate";
import MerchantsOverview from "./components/MerchantsOverview";
import MerchantsSingle from "./components/MerchantsSingle";
import NotAuthorized from "./components/NotAuthorized";
import PermissionGuard from "./components/PermissionGuard";
import PromotionCreate from "./components/PromotionsCreate";
import PromotionsOverview from "./components/PromotionsOverview";
import PromotionsSingle from "./components/PromotionsSingle";
import ReportingLanding from "./components/ReportingLanding";
import RideSingleInformation from "./components/RideSingleInformation";
import RiderHomeAnnouncementSingle from "./components/RiderHomeAnnouncementSingle";
import RidesRecapOverview from "./components/RidesRecapOverview";
import RouteStopsPerRoute from "./components/RouteStopsPerRoute";
import RouteCreate from "./components/RoutesCreate";
import RoutesOverview from "./components/RoutesOverview";
import RouteSingle from "./components/RoutesSingle";
import ShiftSingle from "./components/ShiftSingle";
import ShiftsScheduledCreate from "./components/ShiftsScheduledCreate";
import ShiftsScheduledOverview from "./components/ShiftsScheduledOverview";
import StopCreate from "./components/StopsCreate";
import StopsOverview from "./components/StopsOverview";
import StopSingle from "./components/StopsSingle";
import SystemParametersOverview from "./components/SystemParametersOverview";
import SystemParametersSingle from "./components/SystemParametersSingle";
import UsersRidersOverview from "./components/UsersRidersOverview";
import UsersRidersSegmentCreate from "./components/UsersRidersSegmentsCreate";
import UsersRidersSegmentsOverview from "./components/UsersRidersSegmentsOverview";
import UsersRidersSegmentsSingle from "./components/UsersRidersSegmentsSingle";
import UsersRidersSingle from "./components/UsersRidersSingle";
import UsersSysCreate from "./components/UsersSysCreate";
import UsersSysOverview from "./components/UsersSysOverview";
import UsersSysSingle from "./components/UsersSysSingle";
import VehicleCreate from "./components/VehicleCreate";
import VehicleOverview from "./components/VehicleOverview";
import VehicleSingle from "./components/VehicleSingle";
import VehicleTypeCreate from "./components/VehicleTypeCreate";
import VehicleTypesLanding from "./components/VehicleTypesLanding";
import VehicleTypesOverview from "./components/VehicleTypesOverview";
import ZoneCreate from "./components/ZoneCreate";
import ZoneDeviatedCreate from "./components/ZoneDeviatedCreate";
import ZoneDeviatedOverview from "./components/ZoneDeviatedOverview";
import ZoneDeviatedSingleWrapper from "./components/ZoneDeviatedSingleWrapper";
import ZoneOverview from "./components/ZoneOverview";
import ZoneSingleWrapper from "./components/ZoneSingleWrapper";
import "./index.css";
import DashboardLayout from "./layouts/DashboardLayout";
import Home from "./views/Home";
import NotFound from "./views/NotFound";
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: ["-apple-system", "Open Sans", "Epilogue"].join(","),
      textTransform: "none",
      fontSize: 16,
    },
  },
});
function App() {
  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider
          maxSnack={5}
          autoHideDuration={2500}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <LoadScript
            googleMapsApiKey={
              process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string
            }
            // libraries={libraries}
            libraries={["drawing", "geometry", "places"]}
          >
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<Home></Home>}></Route>
                <Route
                  path="/dashboard"
                  element={
                    <AuthenticatedRoute>
                      <DashboardLayout></DashboardLayout>
                    </AuthenticatedRoute>
                  }
                >
                  <Route
                    path="/dashboard"
                    element={<DashboardHome></DashboardHome>}
                  ></Route>
                  <Route
                    path="/dashboard/vehicles"
                    element={
                      <PermissionGuard allowablePermissionTypes={[255]}>
                        <VehicleOverview></VehicleOverview>
                      </PermissionGuard>
                    }
                  ></Route>
                  <Route
                    path="/dashboard/vehicles/create"
                    element={
                      <PermissionGuard allowablePermissionTypes={[255]}>
                        <VehicleCreate></VehicleCreate>
                      </PermissionGuard>
                    }
                  ></Route>
                  <Route
                    path="/dashboard/vehicles/:id"
                    element={
                      <PermissionGuard allowablePermissionTypes={[255]}>
                        <VehicleSingle></VehicleSingle>
                      </PermissionGuard>
                    }
                  ></Route>
                  <Route
                    path="/dashboard/vehicle-types"
                    element={
                      <PermissionGuard allowablePermissionTypes={[255]}>
                        <VehicleTypesOverview></VehicleTypesOverview>
                      </PermissionGuard>
                    }
                  ></Route>
                  <Route
                    path="/dashboard/vehicle-types/create"
                    element={
                      <PermissionGuard allowablePermissionTypes={[255]}>
                        <VehicleTypeCreate></VehicleTypeCreate>
                      </PermissionGuard>
                    }
                  ></Route>
                  <Route
                    path="/dashboard/vehicle-types/:id"
                    element={
                      <PermissionGuard allowablePermissionTypes={[255]}>
                        <VehicleTypesLanding></VehicleTypesLanding>
                      </PermissionGuard>
                    }
                  ></Route>
                  <Route
                    path="/dashboard/zones"
                    element={
                      <PermissionGuard allowablePermissionTypes={[255]}>
                        <ZoneOverview></ZoneOverview>
                      </PermissionGuard>
                    }
                  ></Route>
                  <Route
                    path="/dashboard/zones/create"
                    element={
                      <PermissionGuard allowablePermissionTypes={[255]}>
                        <ZoneCreate></ZoneCreate>
                      </PermissionGuard>
                    }
                  ></Route>
                  <Route
                    path="/dashboard/zones/:id"
                    element={
                      <PermissionGuard allowablePermissionTypes={[255]}>
                        <ZoneSingleWrapper></ZoneSingleWrapper>
                      </PermissionGuard>
                    }
                  ></Route>
                  <Route
                    path="/dashboard/zones-deviated"
                    element={
                      <PermissionGuard allowablePermissionTypes={[255]}>
                        <ZoneDeviatedOverview></ZoneDeviatedOverview>
                      </PermissionGuard>
                    }
                  ></Route>
                  <Route
                    path="/dashboard/zones-deviated/:id"
                    element={
                      <PermissionGuard allowablePermissionTypes={[255]}>
                        <ZoneDeviatedSingleWrapper></ZoneDeviatedSingleWrapper>
                      </PermissionGuard>
                    }
                  ></Route>
                  <Route
                    path="/dashboard/zones-deviated/create"
                    element={
                      <PermissionGuard allowablePermissionTypes={[255]}>
                        <ZoneDeviatedCreate></ZoneDeviatedCreate>
                      </PermissionGuard>
                    }
                  ></Route>
                  <Route
                    path="/dashboard/addresses"
                    element={
                      <PermissionGuard allowablePermissionTypes={[255]}>
                        <AddressesLanding></AddressesLanding>
                      </PermissionGuard>
                    }
                  ></Route>
                  <Route
                    path="/dashboard/addresses/edit-multiple"
                    element={
                      <PermissionGuard allowablePermissionTypes={[255]}>
                        <AddressesEditMultiple></AddressesEditMultiple>
                      </PermissionGuard>
                    }
                  ></Route>
                  <Route
                    path="/dashboard/addresses/:id"
                    element={
                      <PermissionGuard allowablePermissionTypes={[255]}>
                        <AddressSingle></AddressSingle>
                      </PermissionGuard>
                    }
                  ></Route>
                  <Route
                    path="/dashboard/merchants"
                    element={
                      <PermissionGuard allowablePermissionTypes={[255]}>
                        <MerchantsOverview></MerchantsOverview>
                      </PermissionGuard>
                    }
                  ></Route>
                  <Route
                    path="/dashboard/merchants/:id"
                    element={
                      <PermissionGuard allowablePermissionTypes={[255]}>
                        <MerchantsSingle></MerchantsSingle>
                      </PermissionGuard>
                    }
                  ></Route>
                  <Route
                    path="/dashboard/merchants/create"
                    element={
                      <PermissionGuard allowablePermissionTypes={[255]}>
                        <MerchantCreate></MerchantCreate>
                      </PermissionGuard>
                    }
                  ></Route>
                  <Route
                    path="/dashboard/merchant-categories"
                    element={
                      <PermissionGuard allowablePermissionTypes={[255]}>
                        <MerchantCategories></MerchantCategories>
                      </PermissionGuard>
                    }
                  ></Route>
                  <Route
                    path="/dashboard/merchant-categories/create"
                    element={
                      <PermissionGuard allowablePermissionTypes={[255]}>
                        <MerchantCategoryCreate></MerchantCategoryCreate>
                      </PermissionGuard>
                    }
                  ></Route>
                  <Route
                    path="/dashboard/merchant-categories/:id"
                    element={
                      <PermissionGuard allowablePermissionTypes={[255]}>
                        <MerchantCategorySingle></MerchantCategorySingle>
                      </PermissionGuard>
                    }
                  ></Route>
                  <Route
                    path="/dashboard/jobs"
                    element={
                      <PermissionGuard allowablePermissionTypes={[255]}>
                        <JobsOverview></JobsOverview>
                      </PermissionGuard>
                    }
                  ></Route>
                  <Route
                    path="/dashboard/jobs/create"
                    element={
                      <PermissionGuard allowablePermissionTypes={[255]}>
                        <JobCreate></JobCreate>
                      </PermissionGuard>
                    }
                  ></Route>
                  <Route
                    path="/dashboard/jobs/:id"
                    element={
                      <PermissionGuard allowablePermissionTypes={[255]}>
                        <JobSingle></JobSingle>
                      </PermissionGuard>
                    }
                  ></Route>
                  <Route
                    path="/dashboard/job-categories"
                    element={
                      <PermissionGuard allowablePermissionTypes={[255]}>
                        <JobCategories></JobCategories>
                      </PermissionGuard>
                    }
                  ></Route>
                  <Route
                    path="/dashboard/job-categories/create"
                    element={
                      <PermissionGuard allowablePermissionTypes={[255]}>
                        <JobCategoryCreate></JobCategoryCreate>
                      </PermissionGuard>
                    }
                  ></Route>
                  <Route
                    path="/dashboard/job-categories/:id"
                    element={
                      <PermissionGuard allowablePermissionTypes={[255]}>
                        <JobCategorySingle></JobCategorySingle>
                      </PermissionGuard>
                    }
                  ></Route>
                  <Route
                    path="/dashboard/system-parameters"
                    element={
                      <PermissionGuard allowablePermissionTypes={[255]}>
                        <SystemParametersOverview></SystemParametersOverview>
                      </PermissionGuard>
                    }
                  ></Route>
                  <Route
                    path="/dashboard/system-parameters/:id"
                    element={
                      <PermissionGuard allowablePermissionTypes={[255]}>
                        <SystemParametersSingle></SystemParametersSingle>
                      </PermissionGuard>
                    }
                  ></Route>
                  <Route
                    path="/dashboard/reporting"
                    element={<ReportingLanding></ReportingLanding>}
                  ></Route>
                  <Route
                    path="/dashboard/fares"
                    element={
                      <PermissionGuard allowablePermissionTypes={[255]}>
                        <FaresOverview></FaresOverview>
                      </PermissionGuard>
                    }
                  ></Route>
                  <Route
                    path="/dashboard/fares/:id"
                    element={
                      <PermissionGuard allowablePermissionTypes={[255]}>
                        <FaresSingle></FaresSingle>
                      </PermissionGuard>
                    }
                  ></Route>
                  <Route
                    path="/dashboard/fares/create"
                    element={
                      <PermissionGuard allowablePermissionTypes={[255]}>
                        <FareCreate></FareCreate>
                      </PermissionGuard>
                    }
                  ></Route>
                  <Route
                    path="/dashboard/access-accounts"
                    element={<AccessAccountLanding></AccessAccountLanding>}
                  ></Route>
                  <Route
                    path="/dashboard/access-accounts/create"
                    element={<AccessAccountCreate></AccessAccountCreate>}
                  ></Route>
                  <Route
                    path="/dashboard/access-accounts/:id"
                    element={<AccessAccountSingle></AccessAccountSingle>}
                  ></Route>
                  <Route
                    path="/dashboard/jobs-access-accounts-rules"
                    element={
                      <PermissionGuard allowablePermissionTypes={[255]}>
                        <JobsAccessAccountsRulesOverview></JobsAccessAccountsRulesOverview>
                      </PermissionGuard>
                    }
                  ></Route>
                  <Route
                    path="/dashboard/jobs-access-accounts-rules/create"
                    element={
                      <PermissionGuard allowablePermissionTypes={[255]}>
                        <JobsAccessAccountsRulesCreate></JobsAccessAccountsRulesCreate>
                      </PermissionGuard>
                    }
                  ></Route>
                  <Route
                    path="/dashboard/jobs-access-accounts-rules/:id"
                    element={
                      <PermissionGuard allowablePermissionTypes={[255]}>
                        <JobsAccessAccountsRulesSingle></JobsAccessAccountsRulesSingle>
                      </PermissionGuard>
                    }
                  ></Route>
                  <Route
                    path="/dashboard/promotions"
                    element={
                      <PermissionGuard allowablePermissionTypes={[255]}>
                        <PromotionsOverview></PromotionsOverview>
                      </PermissionGuard>
                    }
                  ></Route>
                  <Route
                    path="/dashboard/promotions/create"
                    element={
                      <PermissionGuard allowablePermissionTypes={[255]}>
                        <PromotionCreate></PromotionCreate>
                      </PermissionGuard>
                    }
                  ></Route>
                  <Route
                    path="/dashboard/promotions/:id"
                    element={
                      <PermissionGuard allowablePermissionTypes={[255]}>
                        <PromotionsSingle></PromotionsSingle>
                      </PermissionGuard>
                    }
                  ></Route>
                  <Route
                    path="/dashboard/stops"
                    element={
                      <PermissionGuard allowablePermissionTypes={[255]}>
                        <StopsOverview></StopsOverview>
                      </PermissionGuard>
                    }
                  ></Route>
                  <Route
                    path="/dashboard/stops/create"
                    element={
                      <PermissionGuard allowablePermissionTypes={[255]}>
                        <StopCreate></StopCreate>
                      </PermissionGuard>
                    }
                  ></Route>
                  <Route
                    path="/dashboard/stops/:id"
                    element={
                      <PermissionGuard allowablePermissionTypes={[255]}>
                        <StopSingle></StopSingle>
                      </PermissionGuard>
                    }
                  ></Route>
                  <Route
                    path="/dashboard/routes"
                    element={
                      <PermissionGuard allowablePermissionTypes={[255]}>
                        <RoutesOverview></RoutesOverview>
                      </PermissionGuard>
                    }
                  ></Route>
                  <Route
                    path="/dashboard/routes/create"
                    element={
                      <PermissionGuard allowablePermissionTypes={[255]}>
                        <RouteCreate></RouteCreate>
                      </PermissionGuard>
                    }
                  ></Route>
                  <Route
                    path="/dashboard/routes/:id"
                    element={
                      <PermissionGuard allowablePermissionTypes={[255]}>
                        <RouteSingle></RouteSingle>
                      </PermissionGuard>
                    }
                  ></Route>
                  <Route
                    path="/dashboard/routes-stops/:id"
                    element={
                      <PermissionGuard allowablePermissionTypes={[255]}>
                        <RouteStopsPerRoute></RouteStopsPerRoute>
                      </PermissionGuard>
                    }
                  ></Route>
                  <Route
                    path="/dashboard/rider-home-announcement"
                    element={
                      <RiderHomeAnnouncementSingle></RiderHomeAnnouncementSingle>
                    }
                  ></Route>
                  <Route
                    path="/dashboard/shifts-scheduled"
                    element={
                      <PermissionGuard allowablePermissionTypes={[0, 1, 255]}>
                        <ShiftsScheduledOverview></ShiftsScheduledOverview>
                      </PermissionGuard>
                    }
                  ></Route>
                  <Route
                    path="/dashboard/shifts-scheduled/create"
                    element={
                      <PermissionGuard allowablePermissionTypes={[0, 1, 255]}>
                        <ShiftsScheduledCreate></ShiftsScheduledCreate>
                      </PermissionGuard>
                    }
                  ></Route>
                  <Route
                    path="/dashboard/shifts/:id"
                    element={
                      <PermissionGuard allowablePermissionTypes={[255]}>
                        <ShiftSingle></ShiftSingle>
                      </PermissionGuard>
                    }
                  ></Route>
                  <Route
                    path="/dashboard/users-riders"
                    element={
                      <PermissionGuard allowablePermissionTypes={[255]}>
                        <UsersRidersOverview></UsersRidersOverview>
                      </PermissionGuard>
                    }
                  ></Route>
                  <Route
                    path="/dashboard/users-riders/:id"
                    element={
                      <PermissionGuard allowablePermissionTypes={[255]}>
                        <UsersRidersSingle></UsersRidersSingle>
                      </PermissionGuard>
                    }
                  ></Route>
                  <Route
                    path="/dashboard/users-riders-segments"
                    element={
                      <PermissionGuard allowablePermissionTypes={[255]}>
                        <UsersRidersSegmentsOverview></UsersRidersSegmentsOverview>
                      </PermissionGuard>
                    }
                  ></Route>
                  <Route
                    path="/dashboard/users-riders-segments/create"
                    element={
                      <PermissionGuard allowablePermissionTypes={[255]}>
                        <UsersRidersSegmentCreate></UsersRidersSegmentCreate>
                      </PermissionGuard>
                    }
                  ></Route>
                  <Route
                    path="/dashboard/users-riders-segments/:id"
                    element={
                      <PermissionGuard allowablePermissionTypes={[255]}>
                        <UsersRidersSegmentsSingle></UsersRidersSegmentsSingle>
                      </PermissionGuard>
                    }
                  ></Route>
                  <Route
                    path="/dashboard/rides"
                    element={<RidesRecapOverview></RidesRecapOverview>}
                  ></Route>
                  <Route
                    path="/dashboard/rides/:id"
                    element={
                      <PermissionGuard allowablePermissionTypes={[0, 1, 255]}>
                        <RideSingleInformation></RideSingleInformation>
                      </PermissionGuard>
                    }
                  ></Route>
                  <Route
                    path="/dashboard/drivers"
                    element={
                      <PermissionGuard allowablePermissionTypes={[255]}>
                        <DriversOverview></DriversOverview>
                      </PermissionGuard>
                    }
                  ></Route>
                  <Route
                    path="/dashboard/drivers/create"
                    element={
                      <PermissionGuard allowablePermissionTypes={[255]}>
                        <DriverCreate></DriverCreate>
                      </PermissionGuard>
                    }
                  ></Route>
                  <Route
                    path="/dashboard/drivers/:id"
                    element={
                      <PermissionGuard allowablePermissionTypes={[255]}>
                        <DriversSingle></DriversSingle>
                      </PermissionGuard>
                    }
                  ></Route>{" "}
                  <Route
                    path="/dashboard/users-sys"
                    element={
                      <PermissionGuard allowablePermissionTypes={[255]}>
                        <UsersSysOverview></UsersSysOverview>
                      </PermissionGuard>
                    }
                  ></Route>
                  <Route
                    path="/dashboard/users-sys/:id"
                    element={
                      <PermissionGuard allowablePermissionTypes={[255]}>
                        <UsersSysSingle></UsersSysSingle>
                      </PermissionGuard>
                    }
                  ></Route>
                  <Route
                    path="/dashboard/users-sys/create"
                    element={
                      <PermissionGuard allowablePermissionTypes={[255]}>
                        <UsersSysCreate></UsersSysCreate>
                      </PermissionGuard>
                    }
                  ></Route>
                  {/* <Route
                    path="/dashboard/users-sys/:id"
                    element={
                      <PermissionGuard allowablePermissionTypes={[255]}>
                        <DriversSingle></DriversSingle>
                      </PermissionGuard>
                    }
                  ></Route> */}
                  <Route
                    path="/dashboard/driver-recap"
                    element={<DriverRecapOverview></DriverRecapOverview>}
                  ></Route>
                  <Route
                    path="/dashboard/hub-recap"
                    element={<HubRecapOverview></HubRecapOverview>}
                  ></Route>
                  <Route
                    path="/dashboard/not-authorized"
                    element={<NotAuthorized></NotAuthorized>}
                  ></Route>
                </Route>
                <Route path="*" element={<NotFound></NotFound>}></Route>
              </Routes>
            </BrowserRouter>
          </LoadScript>
        </SnackbarProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
