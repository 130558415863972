import { LinearProgress } from "@mui/material";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import { getARide } from "../api/rides.api";
import { useSysParamsStore } from "../stores/sysParams.store.zus";
import RideSingleStatusOptions from "./utility/RideSingleStatusOptions";
import dayjs from "dayjs";
import UserRiderCreatedFromOptions from "./utility/UserRiderCreatedFromOptions";
import RideSingleModeOptions from "./utility/RideSingleModeOptions";
import StaticMapPreviewModalPoint from "./StaticMapPreviewModalPoint";
import BackButton from "./BackButton";

export default function RideSingleInformation() {
  const { id } = useParams() as { id: string };

  const { data, status, isLoading, isRefetching } = useQuery(
    "getARide",
    () => getARide(parseInt(id)),
    { refetchOnMount: true }
  );

  if (isLoading || isRefetching) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }
  return (
    <div>
      <BackButton></BackButton>
      <h2>Ride Information </h2>
      <div className={"asset-container"}>
        <div className="field-block">
          <span className="label">Ride Id</span>
          {data?.rides[0].id}
        </div>
        <div className="field-block">
          <span className="label">Creation Date</span>
          {dayjs(data?.rides[0].creation_date! * 1000)
            .tz(
              useSysParamsStore.getState().getParamByName("global_timezone")
                ?.value
            )
            .format("MM/DD/YYYY hh:mm a")}
        </div>
        <div className="field-block">
          <span className="label">Created From</span>
          <UserRiderCreatedFromOptions
            status={data?.rides[0].created_from!}
          ></UserRiderCreatedFromOptions>
        </div>
        {/* MAKE A MODE COMPONENT */}
        <div className="field-block">
          <span className="label">Mode</span>
          <RideSingleModeOptions
            mode={data?.rides[0].mode!}
          ></RideSingleModeOptions>
        </div>
        {/* Magnifying glass for location */}
        <div className="field-block">
          <span className="label">Pickup</span>
          {data?.rides[0].pickup_name.split(",")[0]}{" "}
          <StaticMapPreviewModalPoint
            coordinate={{
              lat: data?.rides[0].pickup_lat!,
              lng: data?.rides[0].pickup_lon!,
            }}
          ></StaticMapPreviewModalPoint>
        </div>
        {/* Magnifying glass for location */}
        <div className="field-block">
          <span className="label">Dropoff</span>
          {data?.rides[0].dropoff_name.split(",")[0]}
          <StaticMapPreviewModalPoint
            coordinate={{
              lat: data?.rides[0].dropoff_lat!,
              lng: data?.rides[0].dropoff_lon!,
            }}
          ></StaticMapPreviewModalPoint>
        </div>
        <div className="field-block">
          <span className="label">Passengers</span>
          {data?.rides[0].passengers}
        </div>
        <div className="field-block">
          <span className="label">Status</span>
          <RideSingleStatusOptions
            status={data?.rides[0].status!}
          ></RideSingleStatusOptions>
        </div>
        <div className="field-block">
          <span className="label">Job Name</span>
          {data?.rides[0].job_name}
        </div>
        {/* DRIVER INFORMATION */}
        <div className="field-block">
          <span className="label">Driver Id</span>
          {data?.rides[0].driver_id}
        </div>
        <div className="field-block">
          <span className="label">Driver</span>
          {data?.rides[0].driver_first_name} {data?.rides[0].driver_last_name}
        </div>
        {/* VEHICLE INFORMATION */}
        <div className="field-block">
          <span className="label">Vehicle</span>
          {`${data?.rides[0].vehicle_nickname} (${data?.rides[0].vehicle_type_name})`}
        </div>
        {/* RIDER INFORMATION */}
        <div className="field-block">
          <span className="label">Rider Id</span>
          {data?.rides[0].rider_id}
        </div>
        <div className="field-block">
          <span className="label">Rider</span>
          {data?.rides[0].rider_first_name} {data?.rides[0].rider_last_name}
        </div>
      </div>
    </div>
  );
}
