enum VtzrOverviewTableDirections {
  Forward,
  Both,
}
export interface VtzrOverviewTableEntry {
  vehicle_type_id: number;
  pick_up_zone_id: number;
  drop_off_zone_id: number;
  allowed: number;
  pick_up_zone_name: string;
  drop_off_zone_name: string;
  id?: string;
  direction?: VtzrOverviewTableDirections;
}

export function transformVtzrToOverviewData(vtzr: VtzrOverviewTableEntry[]) {
  let u: VtzrOverviewTableEntry[] = [];
  let i = 0;
  vtzr.forEach((v) => {
    let uid = `${v.pick_up_zone_id}${v.drop_off_zone_id}${v.allowed}`;
    let rid = `${v.drop_off_zone_id}${v.pick_up_zone_id}${v.allowed}`;

    let matches = 0;
    for (i = 0; i < u.length; i++) {
      if (u[i].id == rid) {
        u[i].direction = VtzrOverviewTableDirections.Both;
        matches++;
      }
      if (u[i].id == uid) {
        matches++;
      }
    }
    if (matches == 0) {
      v.id = uid;
      v.direction = VtzrOverviewTableDirections.Forward;
      u.push(v);
    }
  });
  return u;
}
