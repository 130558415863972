import AssessmentIcon from "@mui/icons-material/Assessment";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import HomeIcon from "@mui/icons-material/Home";
import MapIcon from "@mui/icons-material/Map";
import PaidIcon from "@mui/icons-material/Paid";
import PeopleIcon from "@mui/icons-material/People";
import PlaceIcon from "@mui/icons-material/Place";
import RouteIcon from "@mui/icons-material/Route";
import SettingsIcon from "@mui/icons-material/Settings";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import StorefrontIcon from "@mui/icons-material/Storefront";
import WorkIcon from "@mui/icons-material/Work";
import StarsIcon from "@mui/icons-material/Stars";
import TableChartIcon from "@mui/icons-material/TableChart";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import { useAuthStore } from "../stores/auth.store.zus";
import { useSysParamsStore } from "../stores/sysParams.store.zus";
dayjs.extend(advancedFormat);
const routesTopGroup = [
  {
    label: "Home",
    route: "/dashboard",
    icon: <HomeIcon />,
    permissionTypes: [0, 1, 255],
  },
  {
    label: "Daily Recap",
    route: "/dashboard/hub-recap",
    icon: <TableChartIcon />,
    permissionTypes: [0, 1, 255],
  },
  {
    label: "Ride Recap",
    route: "/dashboard/rides",
    icon: <DirectionsCarIcon />,
    permissionTypes: [0, 1, 255],
  },
  {
    label: "Driver Recap",
    route: "/dashboard/driver-recap",
    icon: <PeopleIcon />,
    permissionTypes: [0, 1, 255],
  },
];

const routes = [
  {
    label: "Access Accounts",
    route: "/dashboard/access-accounts",
    icon: <PeopleIcon />,
    permissionTypes: [0, 1, 255],
  },
  {
    label: "Addresses",
    route: "/dashboard/addresses",
    icon: <PlaceIcon />,
    permissionTypes: [255],
  },
  {
    label: "Drivers",
    route: "/dashboard/drivers",
    icon: <PeopleIcon />,
    permissionTypes: [255],
  },
  {
    label: "Fares",
    route: "/dashboard/fares",
    icon: <PaidIcon />,
    permissionTypes: [255],
  },
  {
    label: "Jobs",
    route: "/dashboard/jobs",
    icon: <WorkIcon />,
    permissionTypes: [255],
  },
  {
    label: "Job Categories",
    route: "/dashboard/job-categories",
    icon: <WorkIcon />,
    permissionTypes: [255],
  },
  {
    label: "Merchants",
    route: "/dashboard/merchants",
    icon: <StorefrontIcon />,
    permissionTypes: [255],
  },
  {
    label: "Merchant Categories",
    route: "/dashboard/merchant-categories",
    icon: <StorefrontIcon />,
    permissionTypes: [255],
  },
  {
    label: "Promotions",
    route: "/dashboard/promotions",
    icon: <StarsIcon />,
    permissionTypes: [255],
  },
  {
    label: "Reporting",
    route: "/dashboard/reporting",
    icon: <AssessmentIcon />,
    permissionTypes: [0, 1, 255],
  },
  {
    label: "Riders",
    route: "/dashboard/users-riders",
    icon: <PeopleIcon />,
    permissionTypes: [255],
  },
  {
    label: "Rider Segments",
    route: "/dashboard/users-riders-segments",
    icon: <PeopleIcon />,
    permissionTypes: [255],
  },
  {
    label: "Routes",
    route: "/dashboard/routes",
    icon: <RouteIcon />,
    permissionTypes: [255],
  },
  {
    label: "Shift Blocks",
    route: "/dashboard/shifts-scheduled",
    icon: <CalendarMonthIcon />,
    permissionTypes: [0, 1, 255],
  },
  {
    label: "Stops",
    route: "/dashboard/stops",
    icon: <StopCircleIcon />,
    permissionTypes: [255],
  },
  {
    label: "System Parameters",
    route: "/dashboard/system-parameters",
    icon: <SettingsIcon />,
    permissionTypes: [255],
  },
  {
    label: "System Users",
    route: "/dashboard/users-sys",
    icon: <PeopleIcon />,
    permissionTypes: [255],
  },
  {
    label: "Vehicles",
    route: "/dashboard/vehicles",
    icon: <DirectionsCarIcon />,
    permissionTypes: [255],
  },
  {
    label: "Vehicle Types",
    route: "/dashboard/vehicle-types",
    icon: <DirectionsCarIcon />,
    permissionTypes: [255],
  },
  {
    label: "Zones",
    route: "/dashboard/zones",
    icon: <MapIcon />,
    permissionTypes: [255],
  },
  {
    label: "Zones Deviated",
    route: "/dashboard/zones-deviated",
    icon: <MapIcon />,
    permissionTypes: [255],
  },
];

export default function DashboardSideNavigation() {
  const { db } = useAuthStore();
  const navigate = useNavigate();

  const [time, setTime] = useState(
    dayjs(new Date())
      .tz(useSysParamsStore.getState().getParamByName("global_timezone")?.value)
      .format("hh:mma z")
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(
        dayjs(new Date())
          .tz(
            useSysParamsStore.getState().getParamByName("global_timezone")
              ?.value
          )
          .format("hh:mma z")
      );
    }, 10000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <>
      <div className="dashboard-side-navigation-container">
        <div className="dashboard-side-navigation-group">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "0px 0px 30px 5px",
            }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/dt-hub-small-circle.png`}
              style={{ height: "35px" }}
              alt=""
            />
            <div>
              <h3>{db}</h3>
              <h3>
                <b>{time}</b>
              </h3>
            </div>
          </div>
        </div>
        {routesTopGroup.map((route) => {
          if (
            !route.permissionTypes.includes(useAuthStore.getState().accessType)
          ) {
            return null;
          }
          return (
            <div className="dashboard-side-navigation-group" key={route.route}>
              <NavLink
                to={`${route.route}`}
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                end
              >
                {route.icon}
                <span className="dashboard-side-navigation-text">
                  {route.label}
                </span>
              </NavLink>
            </div>
          );
        })}
        <hr />
        {routes.map((route) => {
          if (
            !route.permissionTypes.includes(useAuthStore.getState().accessType)
          ) {
            return null;
          }
          return (
            <div className="dashboard-side-navigation-group" key={route.route}>
              <NavLink
                to={`${route.route}`}
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                end
              >
                {route.icon}
                <span className="dashboard-side-navigation-text">
                  {route.label}
                </span>
              </NavLink>
            </div>
          );
        })}
      </div>
    </>
  );
}
