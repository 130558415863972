import { MenuItem, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router";
import { queryClient } from "../App";
import { createUserSys } from "../api/usersSys.api";
import BackButton from "./BackButton";
const inputBlock = {
  display: "block",
  margin: "20px 0px",
};

interface CreateUserSysPayload {
  email: string;
  type: number;
  dashboard_default_access_code?: string | null;
  status: number;
}

export default function UsersSysCreate() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [createUserSysPayload, setCreateUserSysPayload] =
    useState<CreateUserSysPayload>({
      email: "",
      type: 1,
      dashboard_default_access_code: "",
      status: 0,
    });

  const { mutateAsync: saveNewUserSys, isLoading: isMutationLoading } =
    useMutation(createUserSys, {
      onSuccess: () => {
        queryClient.invalidateQueries("getUsersSys");
        enqueueSnackbar("User was succesfully created.", {
          variant: "success",
        });
        navigate("/dashboard/users-sys");
      },
      onError: (error: unknown) => {
        enqueueSnackbar(
          `There was a problem creating this user. Error: ${error}`,
          {
            variant: "error",
          }
        );
      },
    });

  async function submitHandler(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    let payload = createUserSysPayload;
    if (payload.dashboard_default_access_code == "") {
      payload.dashboard_default_access_code = null;
    }
    await saveNewUserSys(payload);
  }

  function emailRegexCheck() {
    const { email } = createUserSysPayload;
    if (email == "" || email == undefined) {
      return true;
    }
    if (email) {
      let v = !/^[A-Za-z0-9._+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(email);
      return v;
    }
  }

  function formComplete() {
    const { email, type } = createUserSysPayload;
    if (email && type > -1 && !emailRegexCheck()) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <div className="create-asset-container">
      <BackButton></BackButton>
      <h2>Create a System User</h2>
      <div style={{ marginTop: "20px", maxWidth: "250px" }}>
        <form onSubmit={submitHandler}>
          <TextField
            inputProps={{ maxLength: 255 }}
            required
            size="small"
            type="text"
            fullWidth
            sx={inputBlock}
            label="Email"
            error={emailRegexCheck()}
            helperText={
              emailRegexCheck()
                ? "Please enter a properly formatted email address."
                : ""
            }
            onChange={(e) => {
              setCreateUserSysPayload({
                ...createUserSysPayload,
                email: e.target.value,
              });
            }}
            onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
          ></TextField>
          <TextField
            inputProps={{ required: true, min: 0, max: 255 }}
            required
            size="small"
            fullWidth
            sx={inputBlock}
            label="Type"
            value={createUserSysPayload.type}
            select
            onChange={(e) => {
              setCreateUserSysPayload({
                ...createUserSysPayload,
                type: parseInt(e.target.value),
              });
            }}
            onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
          >
            <MenuItem value={0}>Admin</MenuItem>
            <MenuItem value={1}>Dashboard</MenuItem>
            <MenuItem value={4}>Kiosk</MenuItem>
          </TextField>

          <TextField
            inputProps={{ maxLength: 255 }}
            size="small"
            type="text"
            fullWidth
            sx={inputBlock}
            label="Dashboard Default Access Code "
            onChange={(e) => {
              setCreateUserSysPayload({
                ...createUserSysPayload,
                dashboard_default_access_code: e.target.value,
              });
            }}
            onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
          ></TextField>
          <TextField
            inputProps={{ required: true }}
            required
            size="small"
            select
            fullWidth
            sx={inputBlock}
            label="Status"
            value={createUserSysPayload.status}
            onChange={(e) => {
              setCreateUserSysPayload({
                ...createUserSysPayload,
                status: parseInt(e.target.value),
              });
            }}
          >
            <MenuItem value={0}>Enabled</MenuItem>
            <MenuItem value={1}>Disabled</MenuItem>
            <MenuItem value={2}>Blocked</MenuItem>
          </TextField>
          <button
            className="button-primary"
            style={{ margin: "20px 0px" }}
            type="submit"
            disabled={!formComplete()}
          >
            Create System User
          </button>
        </form>
      </div>
    </div>
  );
}
