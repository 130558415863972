import * as React from "react";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import { enqueueSnackbar } from "notistack";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { getAllZonesKml } from "../../api/zones.api";
import { useAuthStore } from "../../stores/auth.store.zus";

const options = ["Export all Zones as KML"];

export default function ZoneOverviewButtonGroup() {
  const navigate = useNavigate();
  const { db } = useAuthStore();

  const {
    data: kmlExportData,
    isLoading: isKmlExportLoading,
    isRefetching: isKmlExportRefetching,
    refetch: refetchKmlExport,
  } = useQuery("getZoneKml", () => getAllZonesKml(), {
    enabled: false,
    onSuccess: (d) => {
      const url = window.URL.createObjectURL(d);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${db}_zones.kml`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      enqueueSnackbar("Zone KML exported succesfully.", {
        variant: "success",
      });
    },
    onError: (error: unknown) => {
      enqueueSnackbar(
        `There was a problem exporting this zone KML. Error: ${error}`,
        {
          variant: "error",
        }
      );
    },
  });
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(undefined);
  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    setOpen(false);
    if (index === 0) {
      refetchKmlExport();
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <div style={{ display: "flex", justifyItems: "space-between" }}>
        <ButtonGroup
          variant="contained"
          ref={anchorRef}
          aria-label="split button"
        >
          <Button
            onClick={() => {
              navigate("/dashboard/zones/create");
            }}
          >
            Create Zone
          </Button>
          <Button
            size="small"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper
          sx={{
            zIndex: 1,
          }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {options.map((option, index) => (
                      <MenuItem
                        key={option}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                        // style={{
                        //   backgroundColor:
                        //     index === selectedIndex ? "transparent" : "initial", // Set the background color
                        // }}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </React.Fragment>
  );
}
