import {
  Autocomplete,
  LinearProgress,
  MenuItem,
  TextField,
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import { enqueueSnackbar } from "notistack";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router";
import { queryClient } from "../App";
import { getMerchants } from "../api/merchants.api";
import { Promotion, createAPromotion } from "../api/promotions.api";
import { useSysParamsStore } from "../stores/sysParams.store.zus";
import BackButton from "./BackButton";
import FileUploader from "./utility/FileUploader";

dayjs.extend(timezone);

const inputBlock = {
  display: "block",
  margin: "20px 0px",
};
export default function PromotionCreate() {
  const navigate = useNavigate();
  const {
    mutateAsync: createPromotion,
    isLoading: isPromotionSaving,
    isSuccess,
  } = useMutation(createAPromotion, {
    onSuccess: () => {
      queryClient.invalidateQueries("getPromotions");
      queryClient.invalidateQueries("getAPromotion");
      enqueueSnackbar("Promotion was succesfully created.", {
        variant: "success",
      });
      navigate("/dashboard/promotions");
    },
    onError: (error: unknown) => {
      enqueueSnackbar(
        `There was a problem creating this promotion. Error: ${
          (error as any).response.data.error
        }`,
        {
          variant: "error",
        }
      );
    },
  });
  const {
    data: merchantsData,
    status,
    isLoading,
    isRefetching,
  } = useQuery("getMerchants", getMerchants, { refetchOnMount: true });
  const [createPromotionPayload, setCreatePromotionPayload] =
    useState<Promotion>({
      title: "",
      thumb: "",
      details: "",
      priority: 100,
      status: 0,
      merchant_id: undefined,
      begin_date: dayjs(new Date())
        .tz(
          useSysParamsStore.getState().getParamByName("global_timezone")?.value
        )
        .hour(23)
        .minute(59)
        .second(0)
        .unix(),
      end_date: dayjs(new Date())
        .tz(
          useSysParamsStore.getState().getParamByName("global_timezone")?.value
        )
        .hour(23)
        .minute(59)
        .second(0)
        .unix(),
    });

  function iconUploadHandler(url: string) {
    setCreatePromotionPayload({
      ...createPromotionPayload,
      thumb: url,
    });
  }
  async function submitHandler(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    // console.log(createPromotionPayload);
    await createPromotion(createPromotionPayload);
  }
  if (isLoading || isRefetching) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }
  return (
    <div className="create-asset-container">
      <BackButton></BackButton>
      <h2>Create a Promotion</h2>
      {merchantsData && merchantsData.merchants && (
        <div className="create-asset-form">
          <form onSubmit={submitHandler}>
            <TextField
              required
              inputProps={{ autoComplete: "off" }}
              sx={inputBlock}
              size="small"
              label="Title"
              onChange={(e) => {
                setCreatePromotionPayload({
                  ...createPromotionPayload,
                  title: e.target.value,
                });
              }}
            ></TextField>
            <TextField
              required
              inputProps={{ autoComplete: "off" }}
              sx={inputBlock}
              size="small"
              label="Details"
              onChange={(e) => {
                setCreatePromotionPayload({
                  ...createPromotionPayload,
                  details: e.target.value,
                });
              }}
            ></TextField>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["TimePicker"]}>
                <DateTimePicker
                  label="Begin Date"
                  value={
                    dayjs(createPromotionPayload.begin_date! * 1000).tz(
                      useSysParamsStore
                        .getState()
                        .getParamByName("global_timezone")?.value
                    ) as any
                  }
                  onChange={(e: Date | null) => {
                    if (e) {
                      let d = dayjs(e).tz(
                        useSysParamsStore
                          .getState()
                          .getParamByName("global_timezone")?.value
                      );
                      setCreatePromotionPayload({
                        ...createPromotionPayload,
                        begin_date: d.unix(),
                      });
                    }
                  }}
                ></DateTimePicker>
                <DateTimePicker
                  label="End Date"
                  value={
                    dayjs(createPromotionPayload.end_date! * 1000).tz(
                      useSysParamsStore
                        .getState()
                        .getParamByName("global_timezone")?.value
                    ) as any
                  }
                  onChange={(e: Date | null) => {
                    if (e) {
                      let d = dayjs(e).tz(
                        useSysParamsStore
                          .getState()
                          .getParamByName("global_timezone")?.value
                      );
                      setCreatePromotionPayload({
                        ...createPromotionPayload,
                        end_date: d.unix(),
                      });
                    }
                  }}
                ></DateTimePicker>
                {/* <DateTimePicker
                  label="Begin Date"
                  onChange={(e: Date | null) => {
                    if (e) {
                      let d = new Date(e);
                      setCreatePromotionPayload({
                        ...createPromotionPayload,
                        begin_date: convertDateToTimeSinceEpoc(d),
                      });
                    }
                  }}
                ></DateTimePicker> */}
                {/* <DateTimePicker
                  label="End Date"
                  onChange={(e: Date | null) => {
                    if (e) {
                      let d = new Date(e);
                      setCreatePromotionPayload({
                        ...createPromotionPayload,
                        end_date: convertDateToTimeSinceEpoc(d),
                      });
                    }
                  }}
                ></DateTimePicker> */}
              </DemoContainer>
            </LocalizationProvider>
            <TextField
              required
              inputProps={{ autoComplete: "off" }}
              sx={inputBlock}
              size="small"
              label="Priority"
              type="number"
              value={createPromotionPayload.priority}
              onChange={(e) => {
                setCreatePromotionPayload({
                  ...createPromotionPayload,
                  priority: parseInt(e.target.value),
                });
              }}
            ></TextField>

            <Autocomplete
              disablePortal
              id="merchant-select"
              onChange={(val, newVal) =>
                setCreatePromotionPayload({
                  ...createPromotionPayload,
                  merchant_id: newVal?.id!,
                })
              }
              options={merchantsData.merchants}
              getOptionLabel={(merchant) => merchant.name || ""}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Merchant Select" required />
              )}
            />
            {/* <TextField
              inputProps={{ required: true }}
              sx={inputBlock}
              size="small"
              select
              label="Merchant"
              value={createPromotionPayload.merchant_id}
              onChange={(e) => {
                setCreatePromotionPayload({
                  ...createPromotionPayload,
                  merchant_id: parseInt(e.target.value),
                });
              }}
            >
              {merchantsData?.merchants.map((m) => {
                return (
                  <MenuItem value={m.id} key={m.id}>
                    {m.name}
                  </MenuItem>
                );
              })}
            </TextField> */}
            <TextField
              inputProps={{ min: 0, max: 1, required: true }}
              sx={inputBlock}
              size="small"
              type="number"
              label="Status"
              select
              value={createPromotionPayload.status}
              onChange={(e) => {
                setCreatePromotionPayload({
                  ...createPromotionPayload,
                  status: parseInt(e.target.value),
                });
              }}
            >
              <MenuItem value={0}>Enabled</MenuItem>
              <MenuItem value={1}>Disabled</MenuItem>
            </TextField>
            <FileUploader
              queryKey={"promotionCreateThumbnailUrl"}
              success={iconUploadHandler}
              labelText="Upload a thumbnail"
            ></FileUploader>
            <button
              className="button-primary"
              type="submit"
              disabled={
                !createPromotionPayload.merchant_id ||
                !createPromotionPayload.title ||
                !createPromotionPayload.details ||
                !createPromotionPayload.begin_date ||
                !createPromotionPayload.end_date
              }
            >
              Create Promotion
            </button>
          </form>
        </div>
      )}
    </div>
  );
}
