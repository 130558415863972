import { LinearProgress, MenuItem, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router";
import { queryClient } from "../App";

import { enqueueSnackbar } from "notistack";
import {
  AccessAccount,
  getAnAccessAccount,
  updateAnAccessAccount,
} from "../api/accessAccounts.api";
import StatusOptions from "./utility/StatusOptions";
import PlaceholderCharacter from "./utility/PlaceholderCharacter";

export default function AccessAccountSingleInformation() {
  const { id } = useParams() as { id: string };

  const { data, status, isLoading, isRefetching } = useQuery(
    ["getAnAccessAccount", parseInt(id)],
    () => getAnAccessAccount(parseInt(id)),
    { refetchOnMount: true }
  );
  const {
    mutateAsync: updateAccessAccountMutation,
    isLoading: isMutationLoading,
  } = useMutation(updateAnAccessAccount, {
    onSuccess: () => {
      queryClient.invalidateQueries("getAccessAccounts");
      queryClient.invalidateQueries(["getAnAccessAccount", parseInt(id)]);
      enqueueSnackbar("Access Account was succesfully edited.", {
        variant: "success",
      });
    },
    onError: (error: unknown) => {
      enqueueSnackbar(
        `There was a problem editing this Access Account. Error: ${error}`,
        {
          variant: "error",
        }
      );
    },
  });
  const [editEnabled, setEditEnabled] = useState(false);
  const [updatedAcccessAccount, setUpdatedAccessAccount] =
    useState<AccessAccount>({
      id: parseInt(id),
    });
  async function updateAccessAccount() {
    let payload = updatedAcccessAccount;
    if (payload.email?.trim() == "") {
      payload.email = null;
    }
    if (payload.internal_code?.trim() == "") {
      payload.internal_code = null;
    }
    await updateAccessAccountMutation(payload);
    setEditEnabled(false);
  }
  useEffect(() => {
    setUpdatedAccessAccount({ id: parseInt(id) });
  }, [editEnabled]);

  if (isLoading || isRefetching || isMutationLoading) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }
  return (
    <div style={{ marginTop: "40px" }}>
      <h2>
        Access Account Information{" "}
        <button
          onClick={(e) => {
            setEditEnabled(!editEnabled);
          }}
          className="button-primary-sm"
        >
          Edit
        </button>
      </h2>
      <div
        className={editEnabled ? "asset-container editmode" : "asset-container"}
      >
        <form onSubmit={updateAccessAccount}>
          <div className="field-block">
            <span className="label">Name</span>
            {editEnabled ? (
              <TextField
                size="small"
                inputProps={{ maxLength: 512 }}
                defaultValue={data?.access_accounts[0].name}
                placeholder={
                  data?.access_accounts[0].name
                    ? data?.access_accounts[0].name
                    : ""
                }
                onChange={(e) =>
                  setUpdatedAccessAccount({
                    ...updatedAcccessAccount,
                    name: e.target.value,
                  })
                }
              ></TextField>
            ) : (
              data?.access_accounts[0].name
            )}
          </div>
          <div className="field-block">
            <span className="label">Address</span>
            {editEnabled ? (
              <TextField
                size="small"
                inputProps={{ maxLength: 512 }}
                defaultValue={data?.access_accounts[0].address}
                placeholder={
                  data?.access_accounts[0].address
                    ? data?.access_accounts[0].address
                    : ""
                }
                onChange={(e) =>
                  setUpdatedAccessAccount({
                    ...updatedAcccessAccount,
                    address: e.target.value,
                  })
                }
              ></TextField>
            ) : (
              data?.access_accounts[0].address
            )}
          </div>
          <div className="field-block">
            <span className="label">Rider Address Id</span>
            {editEnabled ? (
              <TextField
                type="number"
                size="small"
                defaultValue={data?.access_accounts[0].rider_address_id}
                placeholder={
                  data?.access_accounts[0].rider_address_id
                    ? data?.access_accounts[0].rider_address_id.toString()
                    : ""
                }
                onChange={(e) =>
                  setUpdatedAccessAccount({
                    ...updatedAcccessAccount,
                    rider_address_id: parseInt(e.target.value),
                  })
                }
              ></TextField>
            ) : (
              data?.access_accounts[0].rider_address_id
            )}
          </div>
          <div className="field-block">
            <span className="label">Email</span>
            {editEnabled ? (
              <TextField
                size="small"
                defaultValue={data?.access_accounts[0].email}
                placeholder={
                  data?.access_accounts[0].email
                    ? data?.access_accounts[0].email
                    : ""
                }
                onChange={(e) => {
                  setUpdatedAccessAccount({
                    ...updatedAcccessAccount,
                    email: e.target.value,
                  });
                }}
              ></TextField>
            ) : (
              data?.access_accounts[0].email || (
                <PlaceholderCharacter></PlaceholderCharacter>
              )
            )}
          </div>

          <div className="field-block">
            <span className="label">Free Rides Yearly</span>
            {editEnabled ? (
              <TextField
                type="number"
                size="small"
                defaultValue={data?.access_accounts[0].free_rides_yearly}
                placeholder={
                  data?.access_accounts[0].free_rides_yearly
                    ? data?.access_accounts[0].free_rides_yearly.toString()
                    : ""
                }
                onChange={(e) =>
                  setUpdatedAccessAccount({
                    ...updatedAcccessAccount,
                    free_rides_yearly: parseInt(e.target.value),
                  })
                }
              ></TextField>
            ) : (
              data?.access_accounts[0].free_rides_yearly
            )}
          </div>
          <div className="field-block">
            <span className="label">Max Rides Daily</span>
            {editEnabled ? (
              <TextField
                type="number"
                size="small"
                inputProps={{ maxLength: 512 }}
                defaultValue={data?.access_accounts[0].max_rides_daily}
                placeholder={
                  data?.access_accounts[0].max_rides_daily
                    ? data?.access_accounts[0].max_rides_daily.toString()
                    : ""
                }
                onChange={(e) =>
                  setUpdatedAccessAccount({
                    ...updatedAcccessAccount,
                    max_rides_daily: parseInt(e.target.value),
                  })
                }
              ></TextField>
            ) : (
              data?.access_accounts[0].max_rides_daily
            )}
          </div>
          <div className="field-block">
            <span className="label">Internal Code</span>
            {editEnabled ? (
              <TextField
                size="small"
                defaultValue={data?.access_accounts[0].internal_code || ""}
                placeholder={
                  data?.access_accounts[0].internal_code
                    ? data?.access_accounts[0].internal_code.toString()
                    : ""
                }
                onChange={(e) => {
                  if (e.target.value !== "") {
                    setUpdatedAccessAccount({
                      ...updatedAcccessAccount,
                      internal_code: e.target.value,
                    });
                  } else {
                    setUpdatedAccessAccount({
                      ...updatedAcccessAccount,
                      internal_code: null,
                    });
                  }
                }}
              ></TextField>
            ) : (
              <>
                {data?.access_accounts[0].internal_code == null ||
                data?.access_accounts[0].internal_code == undefined ? (
                  <PlaceholderCharacter></PlaceholderCharacter>
                ) : (
                  data?.access_accounts[0].internal_code
                )}{" "}
              </>
            )}
          </div>
          <div className="field-block">
            <span className="label">Status</span>
            {editEnabled ? (
              <TextField
                size="small"
                label="Status"
                select
                defaultValue={data?.access_accounts[0].status}
                value={updatedAcccessAccount.status}
                onChange={(e) =>
                  setUpdatedAccessAccount({
                    ...updatedAcccessAccount,
                    status: parseInt(e.target.value),
                  })
                }
              >
                <MenuItem value={0}>Enable</MenuItem>
                <MenuItem value={1}>Disabled</MenuItem>
              </TextField>
            ) : (
              <StatusOptions
                status={data?.access_accounts[0].status!}
              ></StatusOptions>
            )}
          </div>
          {editEnabled && (
            <div className="button-container">
              <button
                className="button-outline btn"
                onClick={(e) => {
                  setEditEnabled(false);
                }}
              >
                Cancel
              </button>
              <button
                className="button-primary btn"
                disabled={Object.keys(updatedAcccessAccount).length == 1}
              >
                Save
              </button>
            </div>
          )}
        </form>
      </div>
    </div>
  );
}
