import { LinearProgress, MenuItem, TextField } from "@mui/material";
import dayjs from "dayjs";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router";
import { queryClient } from "../App";
import { UserSys, getAUsersSys, updateAUserSys } from "../api/usersSys.api";
import { useSysParamsStore } from "../stores/sysParams.store.zus";
import BackButton from "./BackButton";
import PlaceholderCharacter from "./utility/PlaceholderCharacter";
import StatusOptions from "./utility/StatusOptions";
import UserSysTypeOptions from "./utility/UserSysTypeOptions";

export default function UsersSysSingle() {
  const { id } = useParams() as { id: string };
  const { data, status, isLoading, isRefetching } = useQuery(
    "getAUsersSys",
    () => getAUsersSys(parseInt(id)),
    { refetchOnMount: true }
  );

  const { mutateAsync, isLoading: isMutationLoading } = useMutation(
    updateAUserSys,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getAUsersSys");
        enqueueSnackbar("User was succesfully edited.", {
          variant: "success",
        });
      },
      onError: (error: unknown) => {
        enqueueSnackbar(
          `There was a problem editing this user. Error: ${error}`,
          {
            variant: "error",
          }
        );
      },
    }
  );
  const [editEnabled, setEditEnabled] = useState(false);
  const [updatedUserSys, setUpdatedUserSys] = useState<UserSys>({
    id: parseInt(id),
  });

  function renderDeleteFields() {
    if (editEnabled && updatedUserSys.status! == 0) {
      return false;
    }
    if (data?.users[0].status! > 0 || updatedUserSys.status! > 0) {
      return true;
    }
    return false;
  }

  function emailRegexCheck() {
    let email = updatedUserSys.email || data?.users[0].email;
    if (email == "" || email == undefined) {
      return true;
    }
    if (email) {
      let v = !/^[A-Za-z0-9._+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(email);
      return v;
    }
  }
  async function updateUserSys() {
    let payload = updatedUserSys;
    await mutateAsync(payload);
    setEditEnabled(false);
  }
  useEffect(() => {
    setUpdatedUserSys({ id: parseInt(id) });
  }, [editEnabled]);

  if (isLoading || isRefetching || isMutationLoading) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }
  return (
    <div>
      <BackButton></BackButton>
      <h2>
        System User Information{" "}
        <button
          onClick={(e) => {
            setEditEnabled(!editEnabled);
          }}
          className="button-primary-sm"
        >
          Edit
        </button>
      </h2>
      <div
        className={editEnabled ? "asset-container editmode" : "asset-container"}
      >
        <form onSubmit={updateUserSys}>
          <div className="field-block">
            <span className="label">Email</span>

            {editEnabled ? (
              <TextField
                inputProps={{ maxLength: 255, required: true }}
                required
                size="small"
                sx={{ width: 300 }}
                type="text"
                label="Email"
                defaultValue={data?.users[0].email}
                error={emailRegexCheck()}
                helperText={
                  emailRegexCheck()
                    ? "Please enter a properly formatted email address."
                    : ""
                }
                onChange={(e) => {
                  setUpdatedUserSys({
                    ...updatedUserSys,
                    email: e.target.value,
                  });
                }}
                onWheel={(e) =>
                  e.target instanceof HTMLElement && e.target.blur()
                }
              ></TextField>
            ) : data?.users[0].email ? (
              data?.users[0].email
            ) : (
              <PlaceholderCharacter></PlaceholderCharacter>
            )}
          </div>
          <div className="field-block">
            <span className="label">Type</span>
            {editEnabled ? (
              <TextField
                inputProps={{ required: true, min: 0, max: 255 }}
                required
                size="small"
                fullWidth
                label="Type"
                sx={{ width: 300 }}
                defaultValue={data?.users[0].type}
                select
                onChange={(e) => {
                  setUpdatedUserSys({
                    ...updatedUserSys,
                    type: parseInt(e.target.value),
                  });
                }}
                onWheel={(e) =>
                  e.target instanceof HTMLElement && e.target.blur()
                }
              >
                <MenuItem value={0}>Admin</MenuItem>
                <MenuItem value={1}>Dashboard</MenuItem>
                <MenuItem value={4}>Kiosk</MenuItem>
              </TextField>
            ) : data?.users[0].type != undefined ? (
              <UserSysTypeOptions
                type={data?.users[0].type}
              ></UserSysTypeOptions>
            ) : (
              <PlaceholderCharacter></PlaceholderCharacter>
            )}
          </div>
          <div className="field-block">
            <span className="label">Dashboard Default Access Code</span>
            {editEnabled ? (
              <TextField
                inputProps={{ min: 0, max: 255 }}
                size="small"
                sx={{ width: 300 }}
                label="Dashboard Default Access Code"
                defaultValue={data?.users[0].dashboard_default_access_code}
                onChange={(e) => {
                  setUpdatedUserSys({
                    ...updatedUserSys,
                    dashboard_default_access_code: e.target.value,
                  });
                }}
                onWheel={(e) =>
                  e.target instanceof HTMLElement && e.target.blur()
                }
              ></TextField>
            ) : data?.users[0].dashboard_default_access_code ? (
              data?.users[0].dashboard_default_access_code
            ) : (
              <PlaceholderCharacter></PlaceholderCharacter>
            )}
          </div>
          <div className="field-block">
            <span className="label">Creation Date</span>
            {data?.users[0].creation_date ? (
              dayjs(new Date(data?.users[0].creation_date * 1000))
                .tz(
                  useSysParamsStore.getState().getParamByName("global_timezone")
                    ?.value
                )
                .format("MM/DD/YYYY hh:mm a")
            ) : (
              <PlaceholderCharacter></PlaceholderCharacter>
            )}
          </div>
          <div className="field-block">
            <span className="label">Update Date</span>
            {data?.users[0].update_date ? (
              dayjs(new Date(data?.users[0].update_date * 1000))
                .tz(
                  useSysParamsStore.getState().getParamByName("global_timezone")
                    ?.value
                )
                .format("MM/DD/YYYY hh:mm a")
            ) : (
              <PlaceholderCharacter></PlaceholderCharacter>
            )}
          </div>
          <div className="field-block">
            <span className="label">Status</span>
            {editEnabled ? (
              <TextField
                inputProps={{ required: true }}
                select
                size="small"
                sx={{ width: 300 }}
                label="Status"
                defaultValue={data?.users[0].status}
                onChange={(e) => {
                  setUpdatedUserSys({
                    ...updatedUserSys,
                    status: parseInt(e.target.value),
                  });
                }}
              >
                <MenuItem value={0}>Enabled</MenuItem>
                <MenuItem value={1}>Disabled</MenuItem>
                <MenuItem value={2}>Blocked</MenuItem>
              </TextField>
            ) : data?.users[0].status != undefined ? (
              <StatusOptions status={data?.users[0].status}></StatusOptions>
            ) : (
              <PlaceholderCharacter></PlaceholderCharacter>
            )}
          </div>
          {renderDeleteFields() && (
            <div className="field-block">
              <span className="label">Delete Date</span>
              {data?.users[0].delete_date ? (
                dayjs(new Date(data?.users[0].delete_date * 1000))
                  .tz(
                    useSysParamsStore
                      .getState()
                      .getParamByName("global_timezone")?.value
                  )
                  .format("MM/DD/YYYY hh:mm a")
              ) : (
                <PlaceholderCharacter></PlaceholderCharacter>
              )}
            </div>
          )}
          {renderDeleteFields() && (
            <div className="field-block">
              <span className="label">Delete Reason</span>
              {editEnabled ? (
                <TextField
                  size="small"
                  sx={{ width: 300 }}
                  inputProps={{ maxLength: 255 }}
                  defaultValue={data?.users[0].delete_reason}
                  placeholder={data?.users[0].delete_reason}
                  onChange={(e) =>
                    setUpdatedUserSys({
                      ...updatedUserSys,
                      delete_reason: e.target.value,
                    })
                  }
                ></TextField>
              ) : data?.users[0].delete_reason ? (
                data?.users[0].delete_reason
              ) : (
                <PlaceholderCharacter></PlaceholderCharacter>
              )}
            </div>
          )}

          {editEnabled && (
            <div className="button-container">
              <button
                className="button-outline btn"
                onClick={(e) => {
                  setEditEnabled(false);
                }}
              >
                Cancel
              </button>
              <button className="button-primary btn">Save</button>
            </div>
          )}
        </form>
      </div>
    </div>
  );
}
